import { Container, Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';

import BasetenIcon from '@/components/Icons/BasetenIcon';
import { links } from '@/localization';
import { usePylon } from '@bolasim/react-use-pylon';

import { FooterWrapper } from './styled';
import { FooterProps } from './types';

function Footer({ wrapperSx, containerProps = {} }: FooterProps) {
  const { show } = usePylon();
  return (
    <Container maxWidth="lg" {...containerProps}>
      <FooterWrapper sx={wrapperSx} direction="row" gap={2} alignItems="center">
        <Stack direction="row" gap={1} alignItems="center">
          <BasetenIcon color="success" sx={{ height: 20 }} />
          <Typography variant="body2">&#xA9; 2025 Baseten</Typography>
        </Stack>
        <Link href={links.basetenDocs} target="blank">
          Documentation
        </Link>
        <Link id="support-footer" onClick={show} sx={{ '&:hover': { cursor: 'pointer' } }}>
          Support
        </Link>
        <Link href={links.basetenStatus} target="blank">
          Status
        </Link>
        <Link href={links.basetenBlog} target="blank">
          Blog
        </Link>
        <Link href={links.basetenChangeLog} target="blank">
          Changelog
        </Link>
        <Link href={links.basetenCareers} target="blank">
          Careers
        </Link>
      </FooterWrapper>
    </Container>
  );
}

export default Footer;
