import { Action } from '@/legacy/store/slices/entities/Views/Actions/types';
import { MuiColor } from '@/types/mui';
import { DragEndEvent, DragMoveEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';

import { CanvasSize, Dimensions } from './Canvas/types';
import { ComponentConfig, ComponentTypes } from './components/types';
import { DatasourceBinding } from './datasources/types';
import { ParamMapSupplier } from './params/types';
import { RefPath } from './refs/types';
import { StateSupplier } from './states/types';
import { Variable as VariableType } from './vars/types';

interface IDraggableResource {
  name: string;
}

interface IDraggableComponent {
  id: string;
}

type IDraggable = IDraggableResource | IDraggableComponent;

type DragEvent = DragStartEvent | DragMoveEvent | DragOverEvent | DragEndEvent;

enum DragType {
  MOVE = 'moveable',
  RESIZE = 'resize',
  INSERT = 'insert',
}

interface ResizeConstraint {
  minDimensions: Dimensions;
}

interface InsertionData {
  id: string;
  type: ComponentTypes;
  dimensions: Dimensions;
}

enum ResourceCategory {
  Component = 'component',
  State = 'state',
  Variable = 'variable',
  Query = 'query',
  Worklet = 'worklet',
  URLParameter = 'urlParam',
}

enum PropertyType {
  Self = 'self',
  Prop = 'prop',
  Field = 'field',
  Accessor = 'accessor',
}

interface Resource {
  category: ResourceCategory;
  type: PropertyType;
  path: string[];
  name: string;
  accessor?: string;
}

enum ResourceKeyLevel {
  Category = 'cat',
  Type = 'type',
}

type ResourceNode = { [k: string]: ResourceNode } | Resource[];
type ResourceTreeKey = ResourceKeyLevel;
type ResourceTree = Partial<Record<ResourceTreeKey, ResourceNode>>;

enum RenderAs {
  Raw = 'raw',
  String = 'string',
  Equation = 'equation', // evaluates template as a math equation
  Logical = 'logical', // evaluates template as a logical expression
  Object = 'object', // evaluates template as a json object
  Collection = 'collection', // evaluates template as an array
}

type InferenceHints = {
  tryEquation: boolean;
};

interface ValueSupplier {
  value: any;
  refs?: string[];
}

enum ResolutionStatus {
  Unresolved = 'unresolved',
  Pending = 'pending',
  Resolved = 'resolved',
  Errored = 'errored',
}

interface Resolution {
  status: ResolutionStatus;
  as: RenderAs;
  resolvedValue?: any;
  chksum?: string;
}

interface ResolutionTarget {
  supplierId: string;
  refs: string[];
  name?: string;
}

type SupplierId = string;
type SupplierMap<T> = Record<SupplierId, T>;

type TargetsPartition = [ResolutionTarget[], ResolutionTarget[]]; // [unresolved, resolved]

type ResolutionTask = {
  id: string;
  type: 'datasources' | 'vars';
  callback?: () => void;
};

interface EventHandler {
  event: string;
  actionId: string;
}

interface ContextualAction {
  label: string;
  variant: MuiColor;
  /**
   * @deprecated use handlerIds instead. Only retained for upgrade logic
   */
  actionId?: string;
  handlerIds: string[];
}

type ContainerMeta = {
  name: string;
  description?: string;
  dimensions?: { w: number; h: number };
  size?: CanvasSize;
  isFrameHidden?: boolean;
};

type ContainerConfig = {
  meta: ContainerMeta;
  props: Record<string, SupplierId>; // these are props that a container/collection can have
  handlers: Array<EventHandler>; // these are event handlers bound on the container
};

type CollectionData = {
  valueSuppliers: SupplierMap<ValueSupplier>;
  resolvedData?: SupplierMap<Resolution>;
};

type ComponentCollection = ContainerConfig & {
  version: string;
  /**
   * all the reference tags that can now be embedded anywhere where a "ValueSupplier" is used
   */
  refs: Record<string, RefPath>;
  /**
   * referenceable param mappings that can be evaluated are stored here
   */
  params: Record<string, ParamMapSupplier>;
  /**
   * holds all the parameter mappings for datasources
   */
  datasources: Record<string, DatasourceBinding>;
  /**
   * this is all the components that is part of this container/collection
   */
  children: Record<string, ComponentConfig>;
  /**
   * these are variables that can be created in the explorer
   */
  vars: Record<string, VariableType>;
  /**
   * dictionary of actions configured for the view
   */
  actions: Record<string, Action>;
  /**
   * these are temporary "settable" states on a page (or within a container)
   */
  states?: Record<string, StateSupplier>;
  /**
   * URL query string parameters that are referenced within the view
   */
  urlParams?: Record<string, SupplierId>;
} & CollectionData;

type ComponentLocator = {
  componentId: string;
  viewId?: string;
};

type RefData = {
  target: RefPath;
  supplierId: SupplierId;
  data: Resolution;
};

type ClipboardState = {
  sourceConfig: ComponentCollection;
  sourceIds: string[];
};

export {
  IDraggable,
  DragEvent,
  DragType,
  ResizeConstraint,
  InsertionData,
  ResourceCategory,
  PropertyType,
  Resource,
  ResourceKeyLevel,
  ResourceTree,
  RenderAs,
  InferenceHints,
  ValueSupplier,
  Resolution,
  ResolutionStatus,
  ResolutionTarget,
  TargetsPartition,
  ResolutionTask,
  EventHandler,
  ContextualAction,
  ContainerMeta,
  ContainerConfig,
  ComponentCollection,
  ComponentLocator,
  ResourceNode,
  RefData,
  SupplierId,
  SupplierMap,
  ClipboardState,
};
