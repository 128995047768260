import { createAsyncThunk } from '@reduxjs/toolkit';

import { LogType } from '@/legacy/Application/Logs/types';
import {
  getLiveDateRange,
  fetchLogs as getLogs,
  transformLogs,
} from '@/legacy/Application/Logs/utils';
import { Logs } from '@/legacy/Application/Toolbar/Logs/types';
import { restartSession } from '@/sections/Console/utils';

import { ThunkAPI } from '../../../types';
import { WorkflowIdentifier } from '../Workflows/types';
import { RestartConsoleSession } from './types';

// Logs
const fetchLogs = createAsyncThunk<Logs, WorkflowIdentifier, ThunkAPI>(
  'toolbar/fetch-logs',
  async (workflowIdentifier, { getState }) => {
    const {
      entities: {
        toolbar: {
          logs: { filters },
        },
      },
    } = getState();
    const logs = await getLogs({
      ...filters,
      type: LogType.USER_CODE,
      entityId: workflowIdentifier.workflowId,
      dateRange: getLiveDateRange(...filters.dateRange),
      releaseEnv: workflowIdentifier.releaseEnv,
    });
    return transformLogs(logs);
  },
);

// Console
const restartConsoleSession: RestartConsoleSession = createAsyncThunk(
  'consoles/restart-session',
  async ({ applicationId }) => {
    const { data } = await restartSession(applicationId);
    const sessionId = data.payload.session_id;

    return { applicationId, sessionId };
  },
);

export { fetchLogs, restartConsoleSession };
