import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EvaluatedResource } from '@/legacy/Application/View/SidePanel/Explorer/ResourceTree/types';
import { ResolutionTask } from '@/legacy/Application/View/types';
import { ReleaseEnv } from '@/types/releaseEnv';

import { isSliceAction } from '../../../utils/ActionUtils';
import { updateWorkflow } from '../Workflows';
import { startDataResolutionListener } from './Middleware/data-resolution-listener';
import { startSaveViewListener } from './Middleware/save-view-listener';
import {
  VIEW_CONFIG_SLICE_NAME,
  doesActionRequireDataResolution,
  viewConfigReducer,
} from './ViewConfigSlice';
import { resolveDataForView } from './data-resolution';
import {
  createView,
  deleteView,
  duplicateView,
  fetchOperatorViewById,
  fetchViewById,
  renameView,
} from './thunks';
import { ViewEntity, ViewIdentifier, ViewsMap } from './types';
import { isViewIdentifier, viewFromResponse } from './utils';

const initialState: ViewsMap = {
  [ReleaseEnv.Draft]: {},
  [ReleaseEnv.Production]: {},
};

function assignView(state: ViewsMap, view: ViewEntity, releaseEnv: ReleaseEnv) {
  state[releaseEnv][view.id] = view;
  resolveDataForView(state[releaseEnv][view.id]);
}

function assignDraftView(state: ViewsMap, action: PayloadAction<ViewEntity>) {
  assignView(state, action.payload, ReleaseEnv.Draft);
}

function makeViewAction<T extends {}>(
  reducer: (view: ViewEntity, action: PayloadAction<T & ViewIdentifier>) => void,
) {
  return (state: ViewsMap, action: PayloadAction<T & ViewIdentifier>) => {
    if (!isViewIdentifier(action.payload)) {
      throw new Error('Expected payload to be a view identifier');
    }
    const { viewId, releaseEnv } = action.payload;
    const view = state[releaseEnv][viewId];

    if (!view) {
      throw new Error(`View not found with id ${viewId} in env ${releaseEnv}`);
    }

    reducer(view, action);
  };
}

const Views = createSlice({
  name: 'views-entities',
  initialState,
  reducers: {
    updateCurrentVersionId: makeViewAction<{ currentVersionId: string }>((view, action) => {
      view.currentVersionId = action.payload.currentVersionId;
    }),
    queueResolution: makeViewAction<{ task: ResolutionTask; callback(): void }>((view, action) => {
      const { task, callback } = action.payload;
      view.resolutionQueue = [...view.resolutionQueue, { ...task, callback }];
    }),
    clearResolutionQueue: makeViewAction<{}>((view) => {
      view.resolutionQueue = [];
    }),
    triggerResolutionCycle: makeViewAction<{}>((view) => {
      resolveDataForView(view);
    }),
    setLastEvaluatedResources: makeViewAction<{
      evaluatedResources: Record<string, EvaluatedResource>;
    }>((view, action) => {
      const { evaluatedResources } = action.payload;
      view.lastEvaluatedResources = evaluatedResources;
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchViewById.fulfilled, (state, { payload, meta }) => {
        const { releaseEnv } = meta.arg;
        assignView(state, payload, releaseEnv);
      })
      .addCase(fetchOperatorViewById.fulfilled, (state, { payload: { view, releaseEnv } }) => {
        assignView(state, view, releaseEnv);
      })
      .addCase(createView.fulfilled, assignDraftView)
      .addCase(duplicateView.fulfilled, assignDraftView)
      .addCase(renameView.fulfilled, (state, action) => {
        const { viewId, name, releaseEnv } = action.meta.arg;
        const view = state[releaseEnv][viewId];
        view.name = name;
        view.currentVersionId = action.payload;
      })
      .addCase(deleteView.fulfilled, (state, { meta }) => {
        const { viewId, releaseEnv } = meta.arg;
        delete state[releaseEnv][viewId];
      })
      .addCase(updateWorkflow, (state, action) => {
        const { entities, workflowIdentifier } = action.payload;
        if (entities.views) {
          Object.values(entities.views).forEach((response) => {
            const view = viewFromResponse(response, workflowIdentifier.workflowId);
            assignView(state, view, workflowIdentifier.releaseEnv);
          });
        }
      })
      // Defer view config slice actions to viewConfigReducer, passing in the appropriate viewConfig
      .addMatcher(
        isSliceAction(VIEW_CONFIG_SLICE_NAME),
        (state, action: PayloadAction<unknown>) => {
          if (!isViewIdentifier(action.payload)) {
            throw new Error(
              `View slice action ${action.type} needs to specify viewId and workflowId`,
            );
          }

          const view = state[action.payload.releaseEnv][action.payload.viewId];

          if (!view) return state;

          const reducerResult = viewConfigReducer(view.config, action);

          if (reducerResult !== undefined) {
            // If reducer result returned a new view config, overwrite old one. Otherwise, it returned void and mutated the state passed in
            view.config = reducerResult;
          }
        },
      )
      .addMatcher(doesActionRequireDataResolution, (state, action: PayloadAction<unknown>) => {
        if (!isViewIdentifier(action.payload)) {
          throw new Error(
            `View slice action ${action.type} needs to specify viewId and workflowId`,
          );
        }

        const view = state[action.payload.releaseEnv][action.payload.viewId];

        if (!view) return state;

        resolveDataForView(view);
      });
  },
});

export const {
  updateCurrentVersionId,
  queueResolution,
  clearResolutionQueue,
  triggerResolutionCycle,
  setLastEvaluatedResources,
} = Views.actions;

export * from './thunks';
// Re-export actions from viewConfigReducer
export {
  addComponentToView,
  addURLParam,
  deleteURLParam,
  duplicateComponentsForView,
  refreshDataSources,
  removeComponentsFromView,
  renameComponentForView,
  setComponentField,
  setData,
  setDatasourceParamRef,
  setState,
  updateActionForView,
  updateComponentForView,
  updateContainerForView,
  updateLayoutForView,
  updateParamsForView,
  updateRefsForView,
  updateURLParams,
  updateValueSupplier,
  updateVariable,
} from './ViewConfigSlice';

startDataResolutionListener();

startSaveViewListener();

export default Views.reducer;
