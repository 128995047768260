import { history } from '@/legacy/store';
import { ConnectedRouter } from 'connected-react-router';

import Routes from './AppRoutes';

function App() {
  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
}

export default App;
