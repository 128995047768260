import mapValues from 'lodash/mapValues';

import { DataSourceViewState } from '@/legacy/store/reducers/DataSourcesViewReducer';
import { ThunkResult } from '@/legacy/store/types';
import graphqlQuery, { graphqlMutation } from '@/legacy/store/utils/ActionUtils';
import { Base64 } from 'js-base64';

import { RpcActionPrefix } from './ActionType';
import {
  DeleteDataSourceDocument,
  SaveDataSourceDocument,
} from './DataSources/__generated__/mutations.generated';
import {
  DataSourcesDocument,
  ExternalConnectionDocument,
  TestExternalConnectionDocument,
} from './DataSources/__generated__/queries.generated';

export function refreshDataSources(): ThunkResult<any> {
  return (dispatch, _, { apolloClient }) =>
    graphqlQuery(DataSourcesDocument, RpcActionPrefix.REFRESH_DATA_SOURCES, dispatch, apolloClient);
}

export function saveDataSource(source: DataSourceViewState): ThunkResult<any> {
  const req = {
    name: source.name,
    envConnections: mapValues(source.envConnections, (connDetails) => ({
      id: connDetails.externalConnectionId,
      params: connDetails.externalConnectionParams,
    })),
    useProductionSettingsForDraft: source.useProductionSettingsForDraft,
    type: source.type,
    id: source.id,
  };

  const encodedRequest = Base64.encode(JSON.stringify(req));
  return (dispatch, _, { apolloClient }) =>
    graphqlMutation(
      SaveDataSourceDocument,
      RpcActionPrefix.REFRESH_DATA_SOURCE,
      dispatch,
      apolloClient,
      { encodedRequest },
    );
}

export function removeDataSource(id: string): ThunkResult<any> {
  return (dispatch, _, { apolloClient }) =>
    graphqlMutation(
      DeleteDataSourceDocument,
      RpcActionPrefix.DELETE_DATA_SOURCE,
      dispatch,
      apolloClient,
      {
        dataSourceId: id,
      },
      { id },
    );
}

export function testExternalConnection(
  connectionType: string,
  connectionParameters: any,
  env: string,
): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) =>
    graphqlQuery(
      TestExternalConnectionDocument,
      RpcActionPrefix.TEST_EXTERNAL_CONNECTION,
      dispatch,
      apolloClient,
      {
        connectionType,
        connectionParameters: JSON.stringify(connectionParameters),
      },
      { env },
    );
}

export function getExternalConnection(connectionId: string): ThunkResult<any> {
  return (_, __, { apolloClient }) =>
    apolloClient
      .query({
        query: ExternalConnectionDocument,
        variables: {
          id: connectionId,
        },
      })
      .then((response) => response.data.externalConnection);
}
