import flattenDeep from 'lodash/flattenDeep';

import applicationRoutes from '@/legacy/Application/routes';
import { applicationsPagePath, dataPagePath, modelsPagePath } from '@/routes/constants';
import { BasetenPageEnum, BasetenPageProps } from '@/routes/types';
import { ReleaseEnv } from '@/types/releaseEnv';

const applicationPagePathBase = '/apps/:workflowId';

const draftOperatorViewRoute: BasetenPageProps = {
  page: BasetenPageEnum.ViewOperator,
  path: '/apps/:workflowId/operator_previews/:viewId',
  additionalProps: {
    releaseEnv: ReleaseEnv.Draft,
  },
};

const Routes: Array<BasetenPageProps> = [
  // workspace routes
  {
    page: BasetenPageEnum.Workspace,
    path: applicationsPagePath,
  },
  {
    page: BasetenPageEnum.Workspace,
    path: dataPagePath,
  },
  {
    page: BasetenPageEnum.Workspace,
    path: modelsPagePath,
  },
  // ==================
  {
    page: BasetenPageEnum.Application,
    path: [
      `${applicationPagePathBase}`,
      // dual env
      `${applicationPagePathBase}/:env`,
    ],
    subPaths: flattenDeep(applicationRoutes.map((page) => page.path)),
  },
  // with dual environment and draft
  draftOperatorViewRoute,
  {
    page: BasetenPageEnum.Settings,
    path: '/settings/:tabId?',
  },
  {
    page: BasetenPageEnum.Class,
    path: '/data/database_models/:classId',
  },
  {
    page: BasetenPageEnum.DeployModelViaTruss,
    path: '/models/deploy',
  },
  {
    page: BasetenPageEnum.DeployModel,
    path: '/deploy/:modelName',
  },
  {
    page: BasetenPageEnum.Model,
    path: '/models/:modelId/:tabId?/:tabEntityId?',
  },
  {
    page: BasetenPageEnum.Chain,
    path: '/chains/:chainId/:tabId?/:tabEntityId?/:chainletId?',
  },
  {
    page: BasetenPageEnum.CreateSharedEndpoint,
    path: '/shared-endpoints/create',
  },
  {
    page: BasetenPageEnum.SharedEndpoint,
    path: '/shared-endpoints/:sharedEndpointId/:tabId?',
  },
  {
    page: BasetenPageEnum.Onboarding,
    path: '/onboarding',
  },
  {
    page: BasetenPageEnum.ModelBuilder,
    path: '/model-builder',
  },
  // Playground
  {
    page: BasetenPageEnum.Playground,
    path: '/playground/:modelFamily/:modelName?',
  },
];

export { applicationPagePathBase, draftOperatorViewRoute };
export default Routes;
