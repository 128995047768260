import { SyntheticEvent } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { useAsOperator } from '@/hooks';
import { DataTabs } from '@/legacy/Data/types';
import { BasetenPageEnum, usePageSwitcher } from '@/routes';
import { objectInsertIf } from '@/utils/collections/insertIf';

import { WorkspaceSections } from '../../types';
import type { NavigationProps } from './types';

function Navigation({ section }: NavigationProps) {
  const switchPage = usePageSwitcher();
  const asOperator = useAsOperator();

  function handleSwitchPage(_: SyntheticEvent, value: WorkspaceSections) {
    switchPage(BasetenPageEnum.Workspace, {
      section: value,
      ...objectInsertIf(value === WorkspaceSections.Data, {
        tabId: DataTabs.DatabaseModels,
      }),
    });
  }

  return (
    <>
      {!asOperator && (
        <Box sx={{ background: (theme) => theme.palette.background.subtle }}>
          <Box
            sx={{
              background: (theme) => theme.palette.background.default,
              borderBottom: 1,
              borderColor: 'divider',
              px: 3,
            }}
          >
            <Tabs value={section} onChange={handleSwitchPage}>
              <Tab label="Models" value={WorkspaceSections.Models} />
              <Tab label="Applications" value={WorkspaceSections.Applications} />
              <Tab label="Data" value={WorkspaceSections.Data} />
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Navigation;
