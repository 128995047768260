import difference from 'lodash/difference';

import { Action, ActionType } from '@/legacy/store/slices/entities/Views/Actions/types';
import unreachable from '@/utils/unreachable';

function extractConfigFromAction(action: Partial<Action>): { config: Action; extraKeys: string[] } {
  const keys = ['type'];
  switch (action.type) {
    case undefined:
      // empty action
      break;
    case ActionType.SetState:
      keys.push('saveState', 'value');
      break;
    case ActionType.TriggerQuery:
      keys.push('queryId', 'paramRef', 'saveState');
      break;
    case ActionType.RunWorklet:
      keys.push('workletId', 'paramRef', 'saveState');
      break;
    case ActionType.ControlComponent:
      keys.push('componentId', 'field', 'value');
      break;
    case ActionType.GoToView:
      keys.push('viewId', 'paramRef');
      break;
    case ActionType.GoToUrl:
      keys.push('url');
      break;
    case ActionType.ShowNotification:
      keys.push('message', 'variant');
      break;
    case ActionType.CopyToClipboard:
      keys.push('value');
      break;
    case ActionType.RefreshDataSources:
      break;
    default:
      unreachable(action);
  }
  const extraKeys = difference(Object.keys(action), keys);
  const config: Record<string, any> = { ...action };
  extraKeys.forEach((key) => {
    delete config[key];
  });
  return {
    config: config as Action,
    extraKeys,
  };
}

export { extractConfigFromAction };
