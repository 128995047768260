import { ThunkResult } from '@/legacy/store/types';
import { Base64 } from 'js-base64';

import { LogEventDocument } from './EventLogger/__generated__/mutations.generated';

export function logEvent(eventName: string, eventPayload?: object): ThunkResult<any> {
  return async (dispatch, getState, { apolloClient }) => {
    const encodedPayload = Base64.encode(JSON.stringify(eventPayload));
    await apolloClient.mutate({
      mutation: LogEventDocument,
      fetchPolicy: 'no-cache',
      variables: {
        name: eventName,
        payload: encodedPayload,
      },
    });
  };
}
