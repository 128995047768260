import { FormEvent, useMemo, useState } from 'react';

import { Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import isEmpty from 'lodash/isEmpty';

import { RadioButton, RadioButtonGroup } from '@/components/RadioButton';
import { ApprovalStatus } from '@/graphql';
import useAsOperator from '@/hooks/useAsOperator';
import { useUpdateOrganizationMutation } from '@/hooks/useOrg/__generated__/mutations.generated';
import useUser from '@/hooks/useUser';
import { useSearchParams } from '@/routes';
import { useAnalytics } from '@/utils/analytics/segment/hooks';
import validate from '@/utils/validators/userName';
import { useSnackbar } from 'notistack';
import { useSetRecoilState } from 'recoil';

import { useExistingOrganizationQuery } from '../../__generated__/queries.generated';
import { onboardingStep } from '../../atoms';
import { useCompleteOnboarding, useExitOnboarding, useProfileData } from '../../hooks';
import { Label } from '../../styled';
import { OnboardingEvent, OnboardingStep } from '../../types';
import type { SignupArgs } from '../../types';
import { Body, Container, Description, Form, Header, NextButton, Title } from '../styled';
import Other from './components/Other';
import { USE_CASES } from './constants';

function getData(e: FormEvent<HTMLFormElement>) {
  return Object.fromEntries(new FormData(e.currentTarget).entries());
}

function ProfileStep() {
  const [searchParams] = useSearchParams();

  const isInvited = useMemo(() => !!searchParams.get('invited'), [searchParams]);
  const user = useUser();
  const isAbleToJoin = user.status === ApprovalStatus.EligibleToJoin;
  const analytics = useAnalytics();
  const isOperator = useAsOperator();
  const { enqueueSnackbar } = useSnackbar();
  const { data: existingOrganizationData } = useExistingOrganizationQuery({
    fetchPolicy: 'no-cache',
  });
  const [updateOrg] = useUpdateOrganizationMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isWorkSelected, setIsWorkSelected] = useState<boolean>(false);
  const [isOtherUseCaseSelected, setIsOtherUseCaseSelected] = useState<boolean>(false);
  const [, setProfileData] = useProfileData();
  const setStep = useSetRecoilState(onboardingStep);
  const completeOnboarding = useCompleteOnboarding();
  const exitOnboarding = useExitOnboarding();
  const showUseCase = !ENV_CONFIG.USE_ABRIDGED_ONBOARDING && !isInvited && !isOperator;

  async function nextStep() {
    if ((ENV_CONFIG.USE_ABRIDGED_ONBOARDING && !isAbleToJoin) || isInvited || isOperator) {
      await completeOnboarding();
      exitOnboarding();

      return;
    }

    if (!isInvited && isAbleToJoin && existingOrganizationData.existingOrganization) {
      setStep(OnboardingStep.JoinExisting);
      return;
    }

    setStep(OnboardingStep.CreateWorkspace);
  }

  function handleChange({
    firstName,
    lastName,
    useCase,
    workspaceName,
    companyName,
    companyWebsite,
    otherUseCase,
  }: Partial<SignupArgs>) {
    const isWorkSelected = useCase?.toLowerCase().trim() === 'work';
    const useCaseOption = useCase === 'other' ? otherUseCase : useCase;

    setIsWorkSelected(isWorkSelected);
    setIsOtherUseCaseSelected(useCase === 'other');

    const areRequiredFieldsEmpty = isEmpty(firstName?.trim()) || isEmpty(lastName?.trim());
    const areModelOnlyOnboardingFieldsEmpty = !isInvited && !isAbleToJoin && isEmpty(workspaceName);
    const isUseCaseEmpty = isWorkSelected
      ? isEmpty(companyName) || isEmpty(companyWebsite)
      : isEmpty(useCaseOption);

    const isDisabled =
      areRequiredFieldsEmpty ||
      (ENV_CONFIG.USE_ABRIDGED_ONBOARDING
        ? areModelOnlyOnboardingFieldsEmpty
        : showUseCase && isUseCaseEmpty);

    setDisabled(isDisabled);
  }

  async function handleSubmit({
    firstName,
    lastName,
    useCase,
    workspaceName,
    companyName,
    companyWebsite,
    otherUseCase,
  }: Partial<SignupArgs>) {
    analytics.track(OnboardingEvent.CompleteProfile, { email: user.email });

    try {
      validate(firstName, lastName);

      setProfileData({
        firstName,
        lastName,
        useCase: useCase === 'other' ? otherUseCase : useCase,
        workspaceName,
        companyName,
        companyWebsite,
      });

      if (ENV_CONFIG.USE_ABRIDGED_ONBOARDING && workspaceName) {
        setLoading(true);
        await updateOrg({ variables: { workspaceName } });

        setLoading(false);
        nextStep();
      } else {
        nextStep();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  const title =
    isInvited && user.workspaceName ? `Welcome to ${user.workspaceName}!` : 'Welcome to Baseten!';

  const description = isInvited
    ? 'Let’s start with a little information to help us get to know you.'
    : 'Share a little information to help us get to know you.';

  const nextButtonTitle =
    (ENV_CONFIG.USE_ABRIDGED_ONBOARDING || isInvited) && !isAbleToJoin ? "Let's go" : 'Continue';

  return (
    <Container>
      <Header>
        <Title data-cy="onb-title">{title}</Title>
        <Description data-cy="onb-description">{description}</Description>
      </Header>
      <Form
        onChange={(ev: FormEvent<HTMLFormElement>) => handleChange(getData(ev))}
        onSubmit={(ev: FormEvent<HTMLFormElement>) => {
          ev.preventDefault();
          handleSubmit(getData(ev));
        }}
      >
        <Body>
          <Stack data-cy="onb-profile" direction="row" spacing={2} sx={{ pr: 12.5, width: '100%' }}>
            <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
              <Label id="first-name-lbl">First name</Label>
              <TextField
                inputProps={{
                  'data-cy': 'first-name',
                }}
                aria-labelledby="first-name-lbl"
                defaultValue={user.firstName}
                name="firstName"
                fullWidth
                autoFocus
              />
            </Stack>

            <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
              <Label id="last-name-lbl">Last name</Label>
              <TextField
                inputProps={{
                  'data-cy': 'last-name',
                }}
                aria-labelledby="last-name-lbl"
                defaultValue={user.lastName}
                name="lastName"
                fullWidth
              />
            </Stack>
          </Stack>

          {ENV_CONFIG.USE_ABRIDGED_ONBOARDING && !isInvited && !isAbleToJoin && (
            <Stack direction="column" spacing={1} sx={{ pr: 12.5, width: '100%' }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                And what do you want to call your workspace?
              </Typography>

              <Typography variant="body1" id="onboarding-workspace-name" sx={{ fontWeight: 500 }}>
                Workspace name
              </Typography>
              <TextField
                name="workspaceName"
                placeholder="e.g. Company name"
                aria-labelledby="onboarding-workspace-name"
                defaultValue={user.workspaceName}
              />
            </Stack>
          )}

          {showUseCase && (
            <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
              <Label>What are you planning on using Baseten for?</Label>

              <RadioButtonGroup size="small" wrapped name="useCase">
                {USE_CASES.map((useCase) => (
                  <RadioButton key={useCase.value} value={useCase.value}>
                    {useCase.label}
                  </RadioButton>
                ))}
                <Other name="otherUseCase" selected={isOtherUseCaseSelected} />
              </RadioButtonGroup>

              {isWorkSelected && (
                <Stack direction="row" gap={2} sx={{ mt: 3 }}>
                  <Box>
                    <Label sx={{ mb: 0.5 }}>Company</Label>
                    <TextField autoFocus name="companyName" placeholder="Your workplace" />
                  </Box>
                  <Box>
                    <Label sx={{ mb: 0.5 }} variant="body1">
                      Company website
                    </Label>
                    <TextField name="companyWebsite" placeholder="mycompany.com" />
                  </Box>
                </Stack>
              )}
            </Stack>
          )}
          <NextButton disabled={disabled} data-cy="onb-submit" loading={loading}>
            {nextButtonTitle}
          </NextButton>
        </Body>
      </Form>
    </Container>
  );
}

export default ProfileStep;
