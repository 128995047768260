import Clear from '@mui/icons-material/Clear';
import { Link } from '@mui/material';

import { links } from '@/localization';

import LargeAlert from '../../LargeAlert';

function Rejected() {
  return (
    <LargeAlert
      icon={Clear}
      title="Unable to approve your account"
      severity="error"
      description={
        <>
          Unfortunately, we were unable to approve your account with the information you provided.
          If you&apos;d still like to use Baseten, reach out to us at&nbsp;
          <Link href={links.basetenSupport}>support@baseten.co.</Link>
        </>
      }
    />
  );
}

export default Rejected;
