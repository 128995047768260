import { PayloadAction, createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import undoable from 'redux-undo';

import difference from 'lodash/difference';
import get from 'lodash/get';
import has from 'lodash/has';
import negate from 'lodash/negate';
import unset from 'lodash/unset';

import { Coords, Dimensions } from '@/legacy/Application/View/Canvas/types';
import { ComponentConfig } from '@/legacy/Application/View/components/types';
import { DatasourceType } from '@/legacy/Application/View/datasources/types';
import { RefMap, RefType } from '@/legacy/Application/View/refs/types';
import { ComponentCollection, Resolution } from '@/legacy/Application/View/types';
import { generateId } from '@/utils/ids';

import { groupByBatch } from '../../../utils/BatchHistory';
import {
  assignStateData,
  cleanupAction,
  cleanupContextualAction,
  cleanupExistingHandlers,
  cleanupParam,
  cleanupRef,
  cleanupValueSupplier,
  clearData,
  cloneComponent,
  findActionsInComponent,
  findAllSuppliersForComponent,
  initializeSuppliers,
  insertState,
  reconcileStates,
  removeState,
  updateRef,
  upsertAction,
} from './data-management';
// import { cloneStrongDependencies } from './strong-clone';
import {
  DataPayload,
  DuplicateComponentsPayload,
  MutateStatePayload,
  RemoveComponentsPayload,
  RenameComponentPayload,
  SetDatasourceParamRefPayload,
  SetStatePayload,
  SyncSharedStatePayload,
  UpdateActionPayload,
  UpdateComponentPayload,
  UpdateContainerPayload,
  UpdateParamsPayload,
  UpdateVariablePayload,
  ValueSupplierPayload,
  ViewIdentifier,
} from './types';
import { makeRootConfig, wrapResolutionValue } from './utils';

const slice = createSlice({
  name: 'view-config',
  initialState: makeRootConfig,
  reducers: {
    addComponentToView(
      viewConfig,
      action: PayloadAction<ViewIdentifier & { component: ComponentConfig; componentId: string }>,
    ) {
      const { componentId, component } = action.payload;
      viewConfig.children[componentId] = component;
      const suppliersToAdd = findAllSuppliersForComponent(component);
      initializeSuppliers(viewConfig.valueSuppliers, suppliersToAdd);
    },
    duplicateComponentsForView(viewConfig, action: PayloadAction<DuplicateComponentsPayload>) {
      const { targets: cloneTargets, sourceConfig } = action.payload;

      cloneTargets.forEach(({ sourceId, targetId, targetLocation }) =>
        cloneComponent(sourceConfig ?? viewConfig, sourceId, viewConfig, targetId, targetLocation),
      );
    },
    removeComponentsFromView(viewConfig, action: PayloadAction<RemoveComponentsPayload>) {
      const { componentIds } = action.payload;
      componentIds.forEach((componentId) => {
        const component = viewConfig.children[componentId];
        if (!component) {
          return;
        }
        const suppliersToClean = findAllSuppliersForComponent(component);
        suppliersToClean.forEach((supplierId) => {
          cleanupValueSupplier(viewConfig, supplierId);
        });
        const actionsToClean = findActionsInComponent(component);
        actionsToClean.forEach((actionId) => {
          cleanupAction(viewConfig, actionId);
        });
        delete viewConfig.children[componentId];
      });
    },
    updateComponentForView(
      viewConfig,
      { payload: { componentId, delta } }: PayloadAction<UpdateComponentPayload>,
    ) {
      const component = viewConfig.children[componentId];
      if (component && delta) {
        if (delta.chrome) {
          component.chrome = {
            ...component.chrome,
            ...delta.chrome,
          };
          if (delta.chrome.headerText) {
            clearData(viewConfig, delta.chrome.headerText);
          }
        }
        if (delta.handlers) {
          if (component.eventHandlers) {
            cleanupExistingHandlers(viewConfig, component.eventHandlers, delta.handlers);
          }
          component.eventHandlers = delta.handlers;
        }
        if (delta.action) {
          if (delta.value) {
            const oldAction = component.contextualActions[delta.action];
            component.contextualActions[delta.action] = delta.value;
            cleanupExistingHandlers(
              viewConfig,
              oldAction?.handlerIds ?? [],
              delta.value.handlerIds ?? [],
            );
          } else {
            cleanupContextualAction(viewConfig, componentId, delta.action);
          }
        }
      }
    },
    updateContainerForView(
      viewConfig,
      { payload: { delta } }: PayloadAction<UpdateContainerPayload>,
    ) {
      if (delta) {
        if (delta.handlers) {
          if (viewConfig.handlers) {
            cleanupExistingHandlers(viewConfig, viewConfig.handlers, delta.handlers);
          }
          viewConfig.handlers = delta.handlers;
        }
        if (delta.meta) {
          viewConfig.meta = {
            ...viewConfig.meta,
            ...delta.meta,
          };
        }
      }
    },
    updateActionForView(viewConfig, action: PayloadAction<UpdateActionPayload>) {
      const { actionId, config } = action.payload;
      if (config) {
        upsertAction(viewConfig, actionId, config);
      } else {
        cleanupAction(viewConfig, actionId);
      }
    },
    updateParamsForView(viewConfig, action: PayloadAction<UpdateParamsPayload>) {
      const { paramId, paramMapSupplier } = action.payload;
      const params = viewConfig.params[paramId];
      if (params) {
        // existing params
        const removedRefs = difference(params.refs, paramMapSupplier.refs);
        removedRefs.forEach((ref) => {
          cleanupRef(viewConfig, ref);
        });
      }
      viewConfig.params[paramId] = paramMapSupplier;
    },
    updateRefsForView(
      viewConfig,
      action: PayloadAction<ViewIdentifier & { updates: RefMap; deletes: string[] }>,
    ) {
      const { updates, deletes } = action.payload;
      const { datasources, refs, urlParams } = viewConfig;
      Object.keys(updates).forEach((refId) => {
        const ref = updates[refId];
        if (ref.type === RefType.DatasourceWorklet) {
          datasources[ref.instanceId] = {
            id: ref.instanceId,
            type: DatasourceType.Worklet,
            workletId: ref.workletId,
          };
        }
        if (ref.type === RefType.DatasourceQueries) {
          datasources[ref.instanceId] = {
            id: ref.instanceId,
            type: DatasourceType.Query,
            queryId: ref.queryId,
          };
        }

        // Add URL param when it's referenced
        if (ref.type === RefType.URLParameter && !urlParams[ref.paramId]) {
          const supplierId = generateId('urlparam');
          urlParams[ref.paramId] = supplierId;
          viewConfig.resolvedData[supplierId] = wrapResolutionValue(null);
        }
        refs[refId] = updates[refId];
      });
      deletes.forEach((refId: string) => {
        cleanupRef(viewConfig, refId);
      });
    },
    updateVariable(
      viewConfig,
      { payload: { varId, variable } }: PayloadAction<UpdateVariablePayload>,
    ) {
      if (!variable) {
        // remove
        const existingVar = viewConfig.vars[varId];
        if (existingVar) {
          cleanupParam(viewConfig, existingVar.paramRef);
          delete viewConfig.vars[varId];
        }
      } else {
        // update
        const params = viewConfig.params[variable.paramRef];
        if (!params) {
          // no param for the ref, create it
          viewConfig.params[variable.paramRef] = {
            map: {},
            refs: [],
          };
        }
        viewConfig.vars[varId] = variable;
        clearData(viewConfig, varId);
      }
    },
    updateValueSupplier(
      viewConfig,
      { payload: { supplierId, value } }: PayloadAction<ValueSupplierPayload>,
    ) {
      viewConfig.valueSuppliers[supplierId] = value;
    },
    setComponentField(
      viewConfig,
      action: PayloadAction<ViewIdentifier & { componentId: string; id: string; data: Resolution }>,
    ) {
      const { componentId, id, data } = action.payload;
      const component = viewConfig.children[componentId];
      if (component) {
        const supplierId = component.fields[id];
        viewConfig.resolvedData[supplierId] = data;
      }
    },
    setData(viewConfig, { payload: { dataType, key, data } }: PayloadAction<DataPayload>) {
      const resourceToUpdate = get(viewConfig, [dataType, key]);
      if (resourceToUpdate) {
        viewConfig.resolvedData[key] = data;
      }
    },
    syncSharedStates(viewConfig, { payload }: PayloadAction<SyncSharedStatePayload>) {
      const { sharedStates } = payload;
      const validSharedStates: string[] = [];
      sharedStates.forEach((sharedStateWithData) => {
        const { name, supplierId, data } = sharedStateWithData;
        insertState(viewConfig, { name, supplierId, isShared: true }, data);
        validSharedStates.push(sharedStateWithData.name);
      });
      // downgrade states from viewConfig that's no longer a sharedState
      Object.values(viewConfig.states).forEach((state) => {
        if (state.isShared && !validSharedStates.includes(state.name)) {
          // state is no longer shared
          removeState(viewConfig, state.name);
        }
      });
    },
    addState(viewConfig, { payload }: PayloadAction<MutateStatePayload>) {
      const { name, supplierId, isShared, data } = payload;
      if (!name || !supplierId) {
        return;
      }
      insertState(viewConfig, { name, supplierId, isShared }, data);
    },
    deleteState(viewConfig, { payload }: PayloadAction<MutateStatePayload>) {
      const { name } = payload;
      if (!name) {
        return;
      }
      removeState(viewConfig, name);
      // TODO(mike): we currently don't have a concept of "deleting state" as we rely
      // on the config within the actions to determine if a "state" is still part of
      // a view or not; however when we change this UX, we can leverage this action
      // to perform the clean up of state here
    },
    setState(viewConfig, action: PayloadAction<SetStatePayload>) {
      const { name, value, error } = action.payload;
      assignStateData(viewConfig, name, { value, error });
    },
    setDatasourceParamRef(viewConfig, action: PayloadAction<SetDatasourceParamRefPayload>) {
      const { instanceId, paramRef } = action.payload;
      const datasource = viewConfig.datasources[instanceId];
      if (datasource) {
        if (datasource.paramRef !== paramRef) {
          cleanupParam(viewConfig, datasource.paramRef);
        }
        datasource.paramRef = paramRef;
        clearData(viewConfig, instanceId);
      }
    },
    updateLayoutForView(
      viewConfig,
      action: PayloadAction<ViewIdentifier & { layout: Record<string, Coords & Dimensions> }>,
    ) {
      const { layout } = action.payload;
      Object.keys(layout).forEach((component) => {
        const componentLayout = layout[component];
        if (componentLayout) {
          viewConfig.children[component].location = componentLayout;
        }
      });
    },
    addURLParam(
      viewConfig,
      { payload: { key, value } }: PayloadAction<ViewIdentifier & { key: string; value: string }>,
    ) {
      viewConfig.urlParams = viewConfig.urlParams ?? {};
      const { urlParams } = viewConfig;

      const supplierId = generateId('urlparam');
      urlParams[key] = supplierId;
      viewConfig.resolvedData[supplierId] = wrapResolutionValue(value);
    },
    deleteURLParam(
      viewConfig,
      { payload: { key } }: PayloadAction<ViewIdentifier & { key: string }>,
    ) {
      const { urlParams } = viewConfig;

      if (has(urlParams, key)) {
        const existingId = urlParams[key];
        clearData(viewConfig, existingId);
        delete urlParams[key];
      }
    },
    updateURLParams(
      viewConfig,
      { payload: { values } }: PayloadAction<ViewIdentifier & { values: Record<string, string> }>,
    ) {
      viewConfig.urlParams = viewConfig.urlParams ?? {};
      const { urlParams } = viewConfig;

      for (const [key, value] of Object.entries(values)) {
        const supplierId = urlParams[key];
        if (supplierId) {
          viewConfig.resolvedData[supplierId] = wrapResolutionValue(value);
        }
      }
    },
    renameComponentForView(
      viewConfig,
      { payload: { oldId, newId } }: PayloadAction<RenameComponentPayload>,
    ) {
      const components = viewConfig.children;

      const viewEntity = components[oldId];
      if (!viewEntity) return viewConfig;

      // Update key in component record
      delete components[oldId];
      components[newId] = viewEntity;

      // Update references
      const refs = Object.values(viewConfig.refs);
      for (const ref of refs) {
        if (ref.type === RefType.ComponentProp || ref.type === RefType.ComponentField) {
          updateRef(ref, oldId, newId);
        }
      }
    },
    refreshDataSources(viewConfig, _action: PayloadAction<ViewIdentifier>) {
      for (const instanceId of Object.keys(viewConfig.datasources)) {
        unset(viewConfig.resolvedData, [instanceId, 'chksum']);
      }
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      // Actions that could result in states being added or removed
      isAnyOf(
        duplicateComponentsForView,
        removeComponentsFromView,
        updateComponentForView,
        updateActionForView,
      ),
      reconcileStates,
    );
  },
});

export const {
  addComponentToView,
  duplicateComponentsForView,
  removeComponentsFromView,
  updateComponentForView,
  updateContainerForView,
  updateActionForView,
  updateParamsForView,
  updateRefsForView,
  setComponentField,
  setData,
  syncSharedStates,
  addState,
  deleteState,
  setState,
  setDatasourceParamRef,
  updateLayoutForView,
  updateVariable,
  updateValueSupplier,
  addURLParam,
  deleteURLParam,
  updateURLParams,
  renameComponentForView,
  refreshDataSources,
} = slice.actions;

export const VIEW_CONFIG_SLICE_NAME = slice.name;

export const undoViewChange = createAction<ViewIdentifier>(`${VIEW_CONFIG_SLICE_NAME}/UNDO`);
export const redoViewChange = createAction<ViewIdentifier>(`${VIEW_CONFIG_SLICE_NAME}/REDO`);

export const doesActionRequireDataResolution = isAnyOf(
  addComponentToView,
  duplicateComponentsForView,
  removeComponentsFromView,
  updateComponentForView,
  updateParamsForView,
  updateRefsForView,
  updateVariable,
  updateValueSupplier,
  setComponentField,
  setData,
  syncSharedStates,
  addState,
  deleteState,
  setState,
  addURLParam,
  deleteURLParam,
  updateURLParams,
  refreshDataSources,
  undoViewChange,
  redoViewChange,
);

// Decorate reducer to add undo/redo support
export const viewConfigReducer = undoable<ComponentCollection>(slice.reducer, {
  undoType: undoViewChange.type,
  redoType: redoViewChange.type,
  filter: negate(
    isAnyOf(
      syncSharedStates,
      addState,
      deleteState,
      setState,
      setData,
      updateURLParams,
      refreshDataSources,
      setComponentField,
    ),
  ),
  groupBy: groupByBatch,
});

export default slice;
