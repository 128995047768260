import { Reducer } from 'redux';

import { combineValidators, fieldEmptyValidator } from '@/components/ParamsForm/Validation';
import ConnectionSpecs, {
  PostgresSpec,
} from '@/legacy/Data/DataSources/DataSourcesTable/ConnectionSpecs';
import moment from 'moment';

import ActionType, { RpcActionPrefix } from '../actions/ActionType';

export interface ConnectionTestStatus {
  ok: boolean;
  msg: string;
}

export interface ConnectionDetails {
  externalConnectionId?: string;
  externalConnectionParams?: Record<string, any>;
  testStatus?: ConnectionTestStatus;
}

export type EnvConnections = Record<string, ConnectionDetails>;
export interface DataSourceViewState {
  id?: string;
  name: string;
  type: string;
  created: moment.Moment;
  updated: moment.Moment;
  envConnections: EnvConnections;
  loading: boolean;
  new: boolean;
  useProductionSettingsForDraft: boolean;
  saveable: boolean;
}

function envConnectionsInit(connectionType: string): EnvConnections {
  const spec = ConnectionSpecs[connectionType];
  return {
    draft: {
      externalConnectionParams: spec.paramsFormSpec.getInitParams(),
    },
    production: {
      externalConnectionParams: spec.paramsFormSpec.getInitParams(),
    },
  };
}

export const DataSourceInit: DataSourceViewState = {
  name: '',
  type: PostgresSpec.type,
  created: moment(),
  updated: moment(),
  envConnections: envConnectionsInit(PostgresSpec.type),
  loading: false,
  new: true,
  useProductionSettingsForDraft: true,
  saveable: false,
};

const dataSourceValidatorBuilder = (dataSource: DataSourceViewState) => {
  const paramsValidator = ConnectionSpecs[dataSource.type].paramsFormSpec.validator();
  if (dataSource.useProductionSettingsForDraft) {
    return combineValidators({
      '.': fieldEmptyValidator('name', 'Data connection name'),
      'envConnections.production.externalConnectionParams': paramsValidator,
    });
  }

  return combineValidators({
    '.': fieldEmptyValidator('name', 'Data connection name'),
    'envConnections.production.externalConnectionParams': paramsValidator,
    'envConnections.draft.externalConnectionParams': paramsValidator,
  });
};

const setSaveable = (dataSource: DataSourceViewState, saveable: boolean) => ({
  ...dataSource,
  saveable,
});

function updateTestStatus(
  dataSource: DataSourceViewState,
  env: string,
  status?: ConnectionTestStatus,
): DataSourceViewState {
  return {
    ...dataSource,
    envConnections: {
      ...dataSource.envConnections,
      [env]: {
        ...dataSource.envConnections[env],
        testStatus: status,
      },
    },
  };
}

const clearTestStatuses = (dataSource: DataSourceViewState) => {
  const productionCleared = updateTestStatus(dataSource, 'production', null);
  return updateTestStatus(productionCleared, 'draft', null);
};

const onEditDataSource = (dataSource: DataSourceViewState): DataSourceViewState => {
  const validationErrors = dataSourceValidatorBuilder(dataSource)(dataSource);
  const saveable = validationErrors.length === 0;
  return clearTestStatuses(setSaveable(dataSource, saveable));
};

export interface DataSourcesViewState {
  showDataSource: boolean;
  saving: boolean;
  error?: string;

  // This is used to force refresh details of a data source
  dataSourceRefreshId: number;

  // null means new data source to be created
  localDataSource?: DataSourceViewState;
}

const DataSourcesViewStateInit: DataSourcesViewState = {
  showDataSource: false,
  saving: false,
  dataSourceRefreshId: 0,
  localDataSource: DataSourceInit,
};

function copyProductionConnectionParamsToDraft(state: DataSourceViewState): DataSourceViewState {
  return {
    ...state,
    envConnections: {
      ...state.envConnections,
      draft: {
        externalConnectionId: state.envConnections.draft.externalConnectionId,
        externalConnectionParams: state.envConnections.production.externalConnectionParams,
      },
    },
  };
}

const localDataSourceReducer = (state: DataSourceViewState, action: any) => {
  switch (action.type) {
    case 'updateConnectionParams': {
      if (state.useProductionSettingsForDraft) {
        // Ignore any changes to draft if instructed to use prod settings for draft
        if (action.env === 'draft') {
          return state;
        }

        // Use production settings for both draft and production
        // Note that we don't use production connection id for draft,
        // idea is to update draft connection with prod settings, not
        // use production connection in draft.
        return onEditDataSource({
          ...state,
          envConnections: {
            ...state.envConnections,
            production: {
              externalConnectionId:
                action.externalConnectionId || state.envConnections.production.externalConnectionId,
              externalConnectionParams: action.externalConnectionParams,
            },
            draft: {
              ...state.envConnections.draft,
              externalConnectionParams: action.externalConnectionParams,
            },
          },
        });
      }

      return onEditDataSource({
        ...state,
        envConnections: {
          ...state.envConnections,
          [action.env]: {
            externalConnectionId:
              action.externalConnectionId || state.envConnections[action.env].externalConnectionId,
            externalConnectionParams: action.externalConnectionParams,
          },
        },
      });
    }
    case 'updateSourceName':
      return onEditDataSource({
        ...state,
        name: action.name,
      });
    case 'updateSourceType':
      return onEditDataSource({
        ...state,
        type: action.sourceType,
        envConnections: envConnectionsInit(action.sourceType),
      });

    case 'loadingConnectionParams':
      return {
        ...state,
        loading: true,
      };

    case 'finishedLoadingConnectionParams':
      return {
        ...state,
        loading: false,
      };
    case 'useProductionSettingsForDraft': {
      let newState = {
        ...state,
        useProductionSettingsForDraft: action.useProductionSettingsForDraft,
      };
      if (action.useProductionSettingsForDraft) {
        newState = copyProductionConnectionParamsToDraft(newState);
      }
      return onEditDataSource(newState);
    }
    case `${RpcActionPrefix.TEST_EXTERNAL_CONNECTION}.Done`: {
      const connectionStatus = JSON.parse(action.payload.connectionStatus);
      const ok = connectionStatus.success;
      const successMsg =
        'The connection was successful. You can safely proceed with saving this connection.';

      return updateTestStatus(state, action.meta.env, {
        ok,
        msg: ok ? successMsg : connectionStatus.error_message,
      });
    }
    case `${RpcActionPrefix.TEST_EXTERNAL_CONNECTION}.Failed`: {
      const connectionStatus = JSON.parse(action.payload.connectionStatus);
      const ok = connectionStatus.success;

      return updateTestStatus(state, action.meta.env, {
        ok,
        msg: ok ? connectionStatus.msg : connectionStatus.error_message,
      });
    }
    default:
      return state;
  }
};

const dataSourcesViewReducer: Reducer<DataSourcesViewState, any> = (
  state = DataSourcesViewStateInit,
  action: any,
) => {
  switch (action.type) {
    case ActionType.SHOW_DATA_SOURCE:
      return {
        ...state,
        showDataSource: true,
        dataSourceRefreshId: state.dataSourceRefreshId + 1,
        localDataSource: {
          ...action.dataSource,
          loading: true,
        },
      };
    case ActionType.HIDE_DATA_SOURCE:
      return {
        ...state,
        showDataSource: false,
      };
    case ActionType.SHOW_CREATE_NEW_DATA_SOURCE_MODAL:
      return {
        ...state,
        showDataSource: true,
        localDataSource: DataSourceInit,
      };
    case `${RpcActionPrefix.REFRESH_DATA_SOURCE}.Start`:
      return {
        ...state,
        saving: true,
      };
    case `${RpcActionPrefix.REFRESH_DATA_SOURCE}.Failed`:
      return {
        ...state,
        saving: false,
      };
    case `${RpcActionPrefix.REFRESH_DATA_SOURCE}.Done`:
      return {
        ...state,
        saving: false,
      };
    default:
      return {
        ...state,
        localDataSource: {
          ...state.localDataSource,
          ...localDataSourceReducer(state.localDataSource, action),
        },
      };
  }
};

export default dataSourcesViewReducer;
