import { createAction } from '@reduxjs/toolkit';

import API from '@/API';
import {
  nodeSelector,
  workflowFromNodeIdSelector,
  workflowFromWorkletIdSelector,
  workletFromNodeIdSelector,
  workletSelector,
} from '@/legacy/store/Selectors';
import { toBackendNode } from '@/legacy/store/reducers/state/Node';
import { updateWorkletEntity } from '@/legacy/store/slices/entities/Worklets';
import { WorkletEntity, WorkletIdentifier } from '@/legacy/store/slices/entities/Worklets/types';
import { clearQuery } from '@/legacy/store/slices/ui/QueryDialog';
import { AsyncDispatch, RootState, ThunkResult } from '@/legacy/store/types';
import {
  BASETEN_ENV_DRAFT,
  BASETEN_ENV_REQUEST_HEADER_KEY,
  executeAsyncWithEvents,
  graphqlMutation,
} from '@/legacy/store/utils/ActionUtils';
import { ReleaseEnv } from '@/types/releaseEnv';
import { handleStaleGraphQLError } from '@/utils/errorHandling';
import { parseExpectingJson } from '@/utils/json';
import { ApolloClient } from '@apollo/client';
import { Base64 } from 'js-base64';
import { normalize, schema } from 'normalizr';

import ActionType, { RpcActionPrefix } from './ActionType';
import { updateContext } from './ViewBuilder/actions';
import { hydrateWorkflow } from './WorkflowAsyncActions';
import { WorkletMutationDocument } from './Worklet/__generated__/mutations.generated';
import { WorkletRunsDocument } from './Worklet/__generated__/queries.generated';

const nodeSchema = new schema.Entity('nodes');
const workletSchema = new schema.Entity('worklets', { nodes: [nodeSchema] });

export const nodeUpdate = (nodeId: string, node: any) => ({
  type: ActionType.NODE_UPDATE,
  meta: {
    nodeId,
    releaseEnv: ReleaseEnv.Draft,
  },
  node,
});

export const BACKEND_WORKLET_ACTIONS = Object.freeze({
  ADD_CHILD: 'add_child',
  ADD_PARENT: 'add_parent',
  DELETE_NODE: 'delete_node',
  SAVE_NODE: 'save_node',
  ADD_ROOT: 'add_root',
  RENAME: 'rename',
  CREATE: 'create',
  DELETE: 'delete',
  CHANGE_ATOM_TYPE: 'change_atom_type',
  SET_EXECUTE_ASYNC_BY_DEFAULT: 'set_execute_async_by_default',
  SET_ALLOWED_DOMAINS: 'set_allowed_domains',
});

export const refreshWorklet = createAction<{
  workletIdentifier: WorkletIdentifier;
  entities: {
    nodes: unknown[];
  };
}>('REFRESH_WORKLET');

function mutateWorklet(
  state: RootState,
  dispatch: AsyncDispatch,
  apolloClient: ApolloClient<Object>,
  workletIdentifier: WorkletIdentifier,
  backendWorkletAction: any,
  rpcFrontendAction: string,
  refresh = true,
) {
  // a worklet id of '' indicates a create case
  let currentVersionId = '';
  const { workletId } = workletIdentifier;
  if (workletId !== '') {
    const worklet = workletSelector(state, workletIdentifier);
    currentVersionId = worklet.currentVersionId;
  }
  return graphqlMutation(WorkletMutationDocument, rpcFrontendAction, dispatch, apolloClient, {
    workletId,
    currentVersionId,
    encodedAction: Base64.encode(JSON.stringify(backendWorkletAction)),
    releaseEnv: ReleaseEnv.Draft,
  }).then((data) => {
    if (refresh) {
      const { entities } = normalize(data.worklet_mutation.worklet, workletSchema);
      // TODO(mike): once we convert NodeReducers to RTK, we can remove this following dispatch
      dispatch(refreshWorklet({ workletIdentifier, entities: entities as any }));
      dispatch(updateWorkletEntity({ worklet: entities.worklets[workletId], workletIdentifier }));
    }
    return data;
  });
}

function mutateNode(
  state: RootState,
  dispatch: AsyncDispatch,
  apolloClient: ApolloClient<Object>,
  nodeId: string,
  backendWorkletAction: any,
  rpcFrontendAction: string,
  refresh = true,
) {
  const worklet = workletFromNodeIdSelector(state, nodeId, ReleaseEnv.Draft);
  return mutateWorklet(
    state,
    dispatch,
    apolloClient,
    { workletId: worklet.id, releaseEnv: ReleaseEnv.Draft },
    backendWorkletAction,
    rpcFrontendAction,
    refresh,
  );
}

export function addChild(parentNodeId: string, atomName: string, edge = 'then'): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const action = {
      type: BACKEND_WORKLET_ACTIONS.ADD_CHILD,
      parent_node_id: parentNodeId,
      atom_name: atomName,
      action: edge,
    };

    return mutateNode(
      getState(),
      dispatch,
      apolloClient,
      parentNodeId,
      action,
      RpcActionPrefix.WORKLET_ADD_CHILD_NODE,
    ).catch((error: Error) => {
      handleStaleGraphQLError(dispatch, error, 'save node', false);
    });
  };
}

export function addRoot(workletId: string, atomName: string): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const action = {
      type: BACKEND_WORKLET_ACTIONS.ADD_ROOT,
      worklet_id: workletId,
      atom_name: atomName,
    };
    const workflowId =
      getState().ui.workflowView.workflowId ||
      workflowFromWorkletIdSelector(getState(), { workletId, releaseEnv: ReleaseEnv.Draft })?.id;
    return mutateWorklet(
      getState(),
      dispatch,
      apolloClient,
      { workletId, releaseEnv: ReleaseEnv.Draft },
      action,
      RpcActionPrefix.WORKLET_ADD_ROOT,
    )
      .then(async () => {
        await dispatch(hydrateWorkflow({ workflowId, releaseEnv: ReleaseEnv.Draft }, true));
      })
      .catch((error: Error) => {
        handleStaleGraphQLError(dispatch, error, 'save worklet', false);
      });
  };
}

export function updateAllowedDomains(workletId: string, allowedDomains: string): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const action = {
      type: BACKEND_WORKLET_ACTIONS.SET_ALLOWED_DOMAINS,
      worklet_id: workletId,
      allowed_domains: allowedDomains,
    };

    return mutateWorklet(
      getState(),
      dispatch,
      apolloClient,
      { workletId, releaseEnv: ReleaseEnv.Draft },
      action,
      RpcActionPrefix.SET_ALLOWED_DOMAINS,
    ).catch((error: Error) => {
      handleStaleGraphQLError(dispatch, error, 'save worklet', false);
    });
  };
}

export function setExecuteAsyncByDefault(
  workletId: string,
  executeAsyncByDefault = false,
): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const action = {
      type: BACKEND_WORKLET_ACTIONS.SET_EXECUTE_ASYNC_BY_DEFAULT,
      worklet_id: workletId,
      set_execute_async_by_default: executeAsyncByDefault,
    };

    return mutateWorklet(
      getState(),
      dispatch,
      apolloClient,
      { workletId, releaseEnv: ReleaseEnv.Draft },
      action,
      RpcActionPrefix.WORKLET_SET_EXECUTE_ASYNC_BY_DEFAULT,
    ).catch((error: Error) => {
      handleStaleGraphQLError(dispatch, error, 'save worklet', false);
    });
  };
}

export function deleteNode(nodeId: string): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const action = {
      type: BACKEND_WORKLET_ACTIONS.DELETE_NODE,
      node_id: nodeId,
    };
    return mutateNode(
      getState(),
      dispatch,
      apolloClient,
      nodeId,
      action,
      RpcActionPrefix.WORKLET_DELETE_NODE,
    ).catch((error: Error) => {
      handleStaleGraphQLError(dispatch, error, 'delete node', false);
    });
  };
}

export const fetchWorkflowWorkletRunOutputData =
  (workflowId: string, workletRunId: string): ThunkResult<any> =>
  (dispatch, getState, { apolloClient }) => {
    return apolloClient
      .query({
        query: WorkletRunsDocument,
        variables: {
          workflow_id: workflowId,
          worklet_run_id: workletRunId,
        },
      })
      .then((value) => {
        return dispatch({
          type: ActionType.GET_WORKLET_RUN_OUTPUT_DATA,
          outputData: value.data.output_data,
        });
      });
  };

export function saveNode(nodeId: string, refresh = true): ThunkResult<any> {
  return (dispatch, getState, { apolloClient }) => {
    const storeNode = nodeSelector(getState(), ReleaseEnv.Draft, nodeId);
    const { saving } = storeNode;

    if (!saving) {
      const action = {
        type: BACKEND_WORKLET_ACTIONS.SAVE_NODE,
        node_id: nodeId,
        node: toBackendNode(storeNode),
      };
      return mutateNode(
        getState(),
        dispatch,
        apolloClient,
        nodeId,
        action,
        RpcActionPrefix.SAVE_NODE,
        refresh,
      ).catch((error: Error) => {
        handleStaleGraphQLError(dispatch, error, 'save node', false);
      });
    }
    return Promise.resolve();
  };
}

export function runNode(nodeId: string, argValues: JSON): ThunkResult<any> {
  return (dispatch, getState) => {
    const asyncAction = () => {
      // TODO(pankaj) This could be expensive consider memoizing using memoized selectors.
      // Currently, we only support running nodes in draft environment
      const workflow = workflowFromNodeIdSelector(getState(), nodeId, ReleaseEnv.Draft);
      const url = `/applications/${workflow.id}/nodes/${nodeId}/invoke`;

      return API.postWithoutCallback(url, {
        node_input: parseExpectingJson(argValues),
        dry_run: false,
      });
    };

    const onSuccess = ({ status, data: response }: any) => {
      if (status === 200) {
        if (response.success) {
          return { status, response };
        }
        const { execution_log: executionLog } = response;
        throw Error(executionLog);
      } else {
        throw Error(JSON.stringify(response, null, 2));
      }
    };

    return executeAsyncWithEvents(
      asyncAction,
      RpcActionPrefix.EXECUTE_NODE,
      { nodeId },
      dispatch,
      onSuccess,
    );
  };
}

export function runWorklet(
  worklet: WorkletEntity,
  releaseEnv: ReleaseEnv,
  argValues: JSON,
  // logging here is to show the user while they are creating views
  // failures that might arise from running worklets
  logVerbose = false,
): ThunkResult<any> {
  return (dispatch, getState) => {
    const asyncAction = () => {
      const workflow = workflowFromWorkletIdSelector(getState(), {
        workletId: worklet.id,
        releaseEnv,
      });
      const url = `/applications/${workflow.id}/${releaseEnv.toLowerCase()}/worklets/${
        worklet.id
      }/invoke`;

      return API.postWithoutCallback(
        url,
        {
          worklet_input: parseExpectingJson(argValues),
          verbose: true,
          // Always use the draft env here. The operator view, which would use the prod env, uses the DataFetcher.
        },
        { [BASETEN_ENV_REQUEST_HEADER_KEY]: BASETEN_ENV_DRAFT },
      );
    };
    // TODO(pankaj) Refactor/reuse this logic, it's duplicated at another location.
    const onSuccess = ({ status, data: response }: any) => {
      if (status === 200) {
        if (response.success) {
          dispatch(updateContext(worklet.name, 'status', status, 'worklets'));
          dispatch(updateContext(worklet.name, 'output', response.worklet_output, 'worklets'));
          dispatch(updateContext(worklet.name, 'latency', response.latency_ms, 'worklets'));
          dispatch(clearQuery());
          return { status, response };
        }

        const { execution_log: executionLog } = response;
        throw Error(executionLog);
      } else {
        throw Error(JSON.stringify(response, null, 2));
      }
    };

    const rpcAction = logVerbose
      ? RpcActionPrefix.EXECUTE_WORKLET_WITH_DEBUG_LOGGING
      : RpcActionPrefix.EXECUTE_WORKLET;

    return executeAsyncWithEvents(
      asyncAction,
      rpcAction,
      { workletId: worklet.id },
      dispatch,
      onSuccess,
    );
  };
}
