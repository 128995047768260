import { useUnmount } from 'react-use';

import { Container, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Meta from '@/components/Meta';
import { PageContent } from '@/components/styled/page';
import { useModelDeployment } from '@/legacy/store/slices/ui/ModelDeployment/hooks';
import { links } from '@/localization';
import Footer from '@/sections/Footer';
import GlobalHeader from '@/sections/GlobalHeader';

import Instructions from './Instructions';
import Shell from './Shell';

const PAGE_TITLE = 'Deploy a model';

function DeployModelViaTruss() {
  const { resetState } = useModelDeployment();

  useUnmount(resetState);

  return (
    <Meta title={`${PAGE_TITLE} | Baseten`}>
      <GlobalHeader page={PAGE_TITLE} />
      <PageContent>
        <Container maxWidth="lg" sx={{ my: 8 }}>
          <Grid container spacing={6}>
            <Grid size={{ xs: 12, md: 8 }}>
              <Typography variant="h2">Deploy a model</Typography>
              <Typography variant="body1" color="text.muted" mt={2}>
                Quickly deploy your model behind an API endpoint with{' '}
                <Link href={links.trussDocsHome} target="_blank">
                  Truss
                </Link>
                , an open-source model packaging library developed by Baseten. Truss makes model
                deployment an interactive, configurable, reliable process.{' '}
                <Link href={links.trussDocsUsage} target="_blank">
                  Learn more
                </Link>
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Instructions />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Shell />
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </PageContent>
    </Meta>
  );
}

export default DeployModelViaTruss;
