import { AppDispatch } from '@/legacy/store/types';

import ActionType from '../ActionType';
import {
  ExpandedWorkflowFragment,
  ExpandedWorkflowMetadataFragment,
} from '../Workflow/__generated__/fragments.generated';
import { ExpandedOperatorWorkflowFragment } from '../Workflows/__generated__/fragments.generated';

export const updateContext = (
  update: string,
  propName: string,
  propValue: string,
  store: string = 'components',
) => ({
  type: ActionType.VIEW_BUILDER_UPDATE_CONTEXT,
  store,
  update,
  propName,
  propValue,
});

export const applicationFetchCompleted = (
  applicationData:
    | ExpandedOperatorWorkflowFragment
    | ExpandedWorkflowFragment
    | ExpandedWorkflowMetadataFragment,
  dispatch: AppDispatch,
) => ({
  type: ActionType.VIEW_BUILDER_APPLICATION_FETCH_COMPLETED,
  applicationData,
  dispatch,
});
