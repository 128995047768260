import * as Sentry from '@sentry/react';
import { IS_PROD } from '@/constants';
import { history } from '@/legacy/store';
import { arrayInsertIf, objectInsertIf } from '@/utils/collections/insertIf';
import { BrowserTracing } from '@sentry/tracing';

function initializeSentry() {
  if (!ENV_CONFIG.SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: ENV_CONFIG.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      ...arrayInsertIf(IS_PROD, [
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ]),
    ],

    // Only report transactions (used for performance monitoring) for 10% of sessions
    tracesSampleRate: 0.1,
    environment: ENV_CONFIG.SENTRY_ENVIRONMENT,

    ...objectInsertIf(IS_PROD, {
      // Session Replay
      // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 0.1,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    }),
  });
}

export { initializeSentry };
