import get from 'lodash/get';
import mapValues from 'lodash/mapValues';

import { getLiveDateRange } from '@/legacy/Application/Logs/utils';
import { ApplicationLog } from '@/legacy/Application/Toolbar/Logs/types';

import { LogFilters } from './types';

function stringifyParams(params: Record<string, string | { value: string }>): string {
  return JSON.stringify(mapValues(params, (val) => get(val, 'value', val)));
}

function filterUiLogs(uiLogs: ApplicationLog[], filters: LogFilters) {
  return uiLogs.filter((log) => {
    const seconds = Number((Number(log.ts) / 1000000).toFixed(0));
    const createdAt = new Date(seconds);
    const [from, to] = getLiveDateRange(...filters.dateRange);
    // TODO(vahan) clarify how search should work
    return (
      createdAt >= from.toDate() &&
      createdAt <= to.toDate() &&
      JSON.stringify(log.msg).includes(filters.regex)
    );
  });
}

export { stringifyParams, filterUiLogs };
