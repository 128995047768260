import { useDispatch } from 'react-redux';

import { EventTypes } from '@/constants/types';
import { fetchOnboardingChecklist } from '@/legacy/store/slices/entities/OnboardingChecklist';

import { logEvent } from './EventLoggerActions';

/**
 * How to use this function (currently a bit hacky but will be addressed soon):
 * const logEvent = useEventLogger(); -> call this function
 * await logEvent(EventTypes.VIEWED_MODEL); -> wait for logEvent dispatch to complete
 */
export function useEventLogger() {
  const dispatch = useDispatch();
  return async (eventName: string, eventPayload?: object) => {
    await dispatch(logEvent(eventName, eventPayload));
    // TODO(sam): also check in here whether the user's checklist hasn't already been completed, then proceed inside the statement
    if (eventName in EventTypes) {
      dispatch(fetchOnboardingChecklist());
    }
  };
}
