import { useMemo } from 'react';

import { useStore } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import useAsOperator from '@/hooks/useAsOperator';
import { selectWorkflowMetadata } from '@/legacy/store/slices/entities/WorkflowMetadata/selectors';
import { WorkflowIdentifier } from '@/legacy/store/slices/entities/Workflows/types';
import { ReleaseEnv } from '@/types/releaseEnv';

import { usePageSwitcher } from './pageSwitcher/usePageSwitcher';
import { BasetenPageEnum, ReadOnlySearchParams } from './types';

// TODO: React Router v6 ships with its own useSearchParams() hook.
// We should switch to that once RR is upgraded.
// https://reactrouter.com/docs/en/v6/examples/search-params
function useSearchParams() {
  const history = useHistory();

  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  function setSearchParam(name: string, value: string, replace = false) {
    if (!value) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }

    if (replace) {
      history.replace({ search: searchParams.toString() });
    } else {
      history.push({ search: searchParams.toString() });
    }
  }

  return [searchParams as ReadOnlySearchParams, setSearchParam] as const;
}

function useApplicationHome() {
  const goToPage = usePageSwitcher();
  const asOperator = useAsOperator();
  const store = useStore();

  return (workflowIdentifier: WorkflowIdentifier) => {
    const workflowMeta = selectWorkflowMetadata(store.getState(), workflowIdentifier);

    if (asOperator) {
      if (workflowMeta.operatorStartingView) {
        goToPage(BasetenPageEnum.ViewOperator, {
          workflowId: workflowMeta.id,
          viewId: workflowMeta.operatorStartingView,
          releaseEnv: ReleaseEnv.Production,
        });
      }
    } else {
      goToPage(BasetenPageEnum.Application, {
        workflowId: workflowMeta.id,
        releaseEnv: ReleaseEnv.Draft,
      });
    }
  };
}

export { useSearchParams, useApplicationHome };
