import { createAsyncThunk } from '@reduxjs/toolkit';

import { FieldViewState } from '@/legacy/store/reducers/ClassViewReducer';
import {
  ActionWrapper,
  Class,
  CreateClassAction,
  DeleteClassAction,
  RemoveFieldAction,
  UDMType,
  udmTypeToString,
} from '@/legacy/store/reducers/state/Udm';
import {
  classFromResponse,
  deleteClass as deleteClassAction,
  updateClass,
} from '@/legacy/store/slices/entities/Classes';
import {
  AppViewLoadingState,
  ontologiesFetchCompleted,
  ontologiesFetchFailed,
  ontologiesFetchStart,
} from '@/legacy/store/slices/ui/AppView';
import { AsyncThunkConfig, ThunkResult } from '@/legacy/store/types';
import { graphqlMutation } from '@/legacy/store/utils/ActionUtils';
import { switchPage } from '@/routes';
import { BasetenPageEnum } from '@/routes/types';
import { Base64 } from 'js-base64';

import ActionType, { RpcActionPrefix } from '../ActionType';
import { unaryAction } from '../ActionUtils';
import { UdmClassBatchMutationDocument } from './__generated__/mutations.generated';
import { OntologyDocument, OntologyQuery } from './__generated__/queries.generated';

export const renameNewClassViewField = (fieldIndex: number, fieldName: string) => ({
  type: ActionType.CLASSVIEW_RENAME_NEW_FIELD,
  fieldIndex,
  fieldName,
});

export const renameExistingClassViewField = (fieldIndex: number, fieldName: string) => ({
  type: ActionType.CLASSVIEW_RENAME_EXISTING_FIELD,
  fieldIndex,
  fieldName,
});

export const changeNewClassViewFieldDataType = (fieldIndex: number, fieldType: UDMType) => ({
  type: ActionType.CLASSVIEW_CHANGE_NEW_FIELD_DATA_TYPE,
  fieldIndex,
  fieldType,
});

export const fetchOntology = createAsyncThunk<
  OntologyQuery['ontology'],
  boolean | void,
  AsyncThunkConfig
>('ontology/fetch', async (refetch = false, { dispatch, getState, extra: { apolloClient } }) => {
  const {
    ui: {
      appView: { ontologies },
    },
  } = getState();

  if (ontologies === AppViewLoadingState.None || refetch) {
    dispatch(ontologiesFetchStart());

    const { data, error } = await apolloClient.query({ query: OntologyDocument });

    if (error) {
      dispatch(ontologiesFetchFailed(error.message));
    } else {
      dispatch(ontologiesFetchCompleted());
    }

    return data.ontology;
  }
});

export function applyUdmClassBatchMutation(
  mutationActions: Array<ActionWrapper>,
): ThunkResult<any> {
  return (dispatch, _, { apolloClient }) => {
    return graphqlMutation(
      UdmClassBatchMutationDocument,
      RpcActionPrefix.UDM_CLASS_BATCH_MUTATION,
      dispatch,
      apolloClient,
      { encodedActionBatch: Base64.encode(JSON.stringify(mutationActions)) },
    ).then((data) => {
      const mutationResponse = data.udm_class_batch_mutation;
      if (mutationResponse) {
        const klass = classFromResponse(mutationResponse.klass);
        dispatch(updateClass(klass));
        dispatch(unaryAction(ActionType.CLASSVIEW_SET_CLASS, 'klass', klass));
        return klass;
      }
      return null;
    });
  };
}

export function createClass(mutation: CreateClassAction): ThunkResult<any> {
  return (dispatch) =>
    dispatch(
      applyUdmClassBatchMutation([
        {
          name: 'create_class',
          body: mutation,
        },
      ]),
    ).then((klass: any) => {
      dispatch(switchPage(BasetenPageEnum.Class, { classId: klass.id }));
      return klass;
    });
}

export function removeField(mutation: RemoveFieldAction): ThunkResult<any> {
  return applyUdmClassBatchMutation([{ name: 'remove_field', body: mutation }]);
}

export function deleteClass(mutation: DeleteClassAction): ThunkResult<any> {
  return async (dispatch) => {
    await dispatch(applyUdmClassBatchMutation([{ name: 'delete_class', body: mutation }]));
    dispatch(deleteClassAction(mutation.classTypename));
  };
}

export function fieldNotEmpty(field: FieldViewState): boolean {
  const isEmpty = field.name === '' && !field.type;
  return !isEmpty;
}

export function classChangesToMutations(
  storedClass: Class,
  existingFields: Array<FieldViewState>,
  newFields: Array<FieldViewState>,
  name: string,
  typename: string,
  description: string,
) {
  const classTypename = storedClass.typename;
  const classNamespace = storedClass.namespace;
  const mutationActions: Array<ActionWrapper> = [];
  existingFields.forEach((field, fieldIndex) => {
    const origField = storedClass.fields[fieldIndex];
    if (field.name !== origField.name) {
      mutationActions.push({
        name: 'rename_field',
        body: {
          classTypename,
          classNamespace,
          fieldName: origField.name,
          newFieldName: field.name,
          field: origField,
        },
      });
    }
  });

  const nonEmptyNewFields = newFields.filter(fieldNotEmpty);
  nonEmptyNewFields.forEach((field) => {
    mutationActions.push({
      name: 'add_field',
      body: {
        classTypename,
        classNamespace,
        field: {
          name: field.name,
          typ: udmTypeToString(field.type),
          defaultValue: null,
          nullable: true,
        },
      },
    });
  });
  if (storedClass.name !== name || storedClass.description !== description) {
    mutationActions.push({
      name: 'update_class',
      body: {
        classId: storedClass.id,
        classTypename,
        classNamespace,
        className: name,
        classDescription: description,
      },
    });
  }
  // Rename of typename should be last, otherwise mutations after will need to use the new typename
  if (classTypename !== typename) {
    mutationActions.push({
      name: 'rename_class',
      body: {
        classTypename,
        classNamespace,
        newClassTypename: typename,
      },
    });
  }
  return mutationActions;
}
