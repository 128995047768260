import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';

import apolloClient from '@/apollo';
import { StateData, StateSupplier } from '@/legacy/Application/View/states/types';
import { ReleaseEnv } from '@/types/releaseEnv';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { normalize } from 'normalizr';

import { createWorkflowOnBackend } from '../../../actions/WorkflowsAsyncActions';
import { ThunkAPI } from '../../../types';
import { createView, duplicateView, fetchOperatorViewById } from '../Views';
import { setState } from '../Views/ViewConfigSlice';
import { PreReleaseDataFragment } from './__generated__/fragments.generated';
import {
  AddSharedStateDocument,
  CreateAndPublishReleaseDocument,
  CreateAndPublishReleaseMutation,
  DeleteSharedStateDocument,
  DeleteWorkflowDocument,
  UpdateStartingViewDocument,
} from './__generated__/mutations.generated';
import {
  PreReleaseDataDocument,
  ReleaseStatusDocument,
  ReleaseStatusQuery,
  WorkflowDocument,
} from './__generated__/queries.generated';
import { insertSharedState } from './data-management';
import { workflowSchema } from './schema';
import { workflowSelector } from './selectors';
import {
  AddSharedStateParams,
  DeleteSharedStateParams,
  UpdateStartingViewParams,
  UpdateWorkflowPayload,
  WorkflowEntity,
  WorkflowIdentifier,
  WorkflowsMap,
} from './types';
import { buildState, makeNewWorkflow, workflowFromResponse } from './utils';

const initialState: WorkflowsMap = {
  [ReleaseEnv.Draft]: {},
  [ReleaseEnv.Production]: {},
};

export const fetchWorkflowById = createAsyncThunk<WorkflowEntity, WorkflowIdentifier, ThunkAPI>(
  'workflows-entities/fetchWorkflowById',
  async (thunkArg, { dispatch, getState, extra, rejectWithValue }) => {
    const { workflowId, releaseEnv } = thunkArg;

    const targetWorkflow = workflowSelector(getState(), thunkArg);

    if (targetWorkflow) {
      return targetWorkflow;
    }

    const { data } = await extra.apolloClient.query({
      query: WorkflowDocument,
      variables: {
        id: workflowId,
        deploymentEnv: releaseEnv,
      },
    });

    const { entities } = normalize(data.workflow, workflowSchema);
    return workflowFromResponse(entities.workflows[workflowId]);
  },
);

export const addSharedState = createAsyncThunk<StateSupplier, AddSharedStateParams, ThunkAPI>(
  'workflows-entities/addSharedState',
  async ({ workflowId, releaseEnv, stateName, withData }, { dispatch, getState, extra }) => {
    await extra.apolloClient.mutate({
      mutation: AddSharedStateDocument,
      variables: {
        workflowId,
        stateName,
      },
    });
    return buildState(stateName, withData);
  },
);

export const deleteSharedState = createAsyncThunk<void, DeleteSharedStateParams, ThunkAPI>(
  'workflows-entities/deleteSharedState',
  async ({ workflowId, releaseEnv, stateName }, { dispatch, getState, extra }) => {
    await extra.apolloClient.mutate({
      mutation: DeleteSharedStateDocument,
      variables: {
        workflowId,
        stateName,
      },
    });
  },
);

export const updateStartingViewForWorkflow = createAsyncThunk<
  UpdateStartingViewParams,
  UpdateStartingViewParams,
  ThunkAPI
>(
  'workflows-entities/updateStartingViewForWorkflow',
  async ({ workflowId, viewId, releaseEnv }, { dispatch, getState, extra }) => {
    await extra.apolloClient.mutate({
      mutation: UpdateStartingViewDocument,
      variables: {
        workflowId,
        viewId,
      },
    });
    return { workflowId, viewId, releaseEnv };
  },
);

export const deleteWorkflow = createAsyncThunk<string, WorkflowIdentifier, ThunkAPI>(
  'workflows-entities/deleteWorkflow',
  async ({ workflowId }, { dispatch, getState, extra }) => {
    await extra.apolloClient.mutate({
      mutation: DeleteWorkflowDocument,
      variables: {
        workflowId,
      },
    });

    return workflowId;
  },
);

export function createAndPublishRelease(
  workflowId: string,
  description: string,
  version: string,
): Promise<FetchResult<CreateAndPublishReleaseMutation>> {
  return apolloClient.mutate({
    mutation: CreateAndPublishReleaseDocument,
    variables: {
      workflowId,
      description,
      version,
    },
  });
}

export function getPublishStatus(id: string): Promise<FetchResult<ReleaseStatusQuery>> {
  return apolloClient.query({
    query: ReleaseStatusDocument,
    variables: {
      id,
    },
  });
}

export function getPreReleaseData(
  workflowId: string,
): Promise<ApolloQueryResult<Record<'pre_release_data', PreReleaseDataFragment>>> {
  return apolloClient.query({
    query: PreReleaseDataDocument,
    fetchPolicy: 'network-only',
    variables: {
      workflowId,
    },
  });
}

const addViewToWorkflow =
  (releaseEnv: ReleaseEnv) =>
  (
    state: WorkflowsMap,
    action: { meta: { arg: { workflowId: string } }; payload: { id: string } },
  ) => {
    const { workflowId } = action.meta.arg;
    const { id: viewId } = action.payload;
    const workflow = state[releaseEnv][workflowId];
    workflow.views = workflow.views.concat(viewId);
  };

const Workflows = createSlice({
  name: 'workflows-entities',
  initialState,
  reducers: {
    updateWorkflow(
      state,
      {
        payload: {
          entities,
          workflowIdentifier: { workflowId, releaseEnv },
        },
      }: PayloadAction<UpdateWorkflowPayload>,
    ) {
      state[releaseEnv][workflowId] = workflowFromResponse(entities.workflows[workflowId]);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkflowById.fulfilled, (state, { payload, meta }) => {
        const { workflowId, releaseEnv } = meta.arg;
        state[releaseEnv][workflowId] = payload;
      })
      .addCase(createWorkflowOnBackend.fulfilled, (state, action) => {
        const workflow = action.payload;
        state[ReleaseEnv.Draft][workflow.id] = makeNewWorkflow(workflow.id);
      })
      .addCase(createView.fulfilled, addViewToWorkflow(ReleaseEnv.Draft))
      .addCase(duplicateView.fulfilled, addViewToWorkflow(ReleaseEnv.Draft))
      .addCase(updateStartingViewForWorkflow.fulfilled, (state, action) => {
        const { workflowId, viewId } = action.payload;
        const workflow = state[ReleaseEnv.Draft][workflowId];
        workflow.startingView = viewId;
      })
      .addCase(deleteWorkflow.fulfilled, (state, action) => {
        delete state[ReleaseEnv.Draft][action.payload];
      })
      .addCase(fetchOperatorViewById.fulfilled, (state, { payload, meta }) => {
        const { releaseEnv, sharedStates } = payload;
        const { workflowId } = meta.arg;
        if (!state[releaseEnv][workflowId]) {
          state[releaseEnv][workflowId] = makeNewWorkflow(workflowId);
        }
        sharedStates.forEach((stateName) => {
          insertSharedState(state[releaseEnv][workflowId], buildState(stateName));
        });
      })
      .addCase(addSharedState.fulfilled, (state, { payload, meta }) => {
        const { workflowId, releaseEnv } = meta.arg;
        insertSharedState(state[releaseEnv][workflowId], payload);
      })
      .addCase(deleteSharedState.fulfilled, (state, { meta }) => {
        const { workflowId, releaseEnv, stateName } = meta.arg;
        const workflow = state[releaseEnv][workflowId];
        const { sharedStates: currentStates } = workflow;
        if (currentStates) {
          workflow.sharedStates = currentStates.filter(
            (sharedState) => sharedState.name !== stateName,
          );
        }
      })
      .addCase(setState, (state, action) => {
        const { workflowId, releaseEnv, name, value, error } = action.payload;
        const workflow = state[releaseEnv][workflowId];
        if (workflow) {
          const matchingState = workflow.sharedStates.find(
            (sharedState) => sharedState.name === name,
          );
          if (matchingState) {
            const stateData: StateData = { value, error };
            if (!isEqual(stateData, matchingState.data)) {
              matchingState.data = stateData;
            }
          }
        }
      });
  },
});

export const { updateWorkflow } = Workflows.actions;

export default Workflows.reducer;
