import { StateWithHistory } from 'redux-undo';

import { EvaluatedResourcesCollection } from '@/legacy/Application/View/SidePanel/Explorer/ResourceTree/types';
import { Location } from '@/legacy/Application/View/components/types';
import { ParamMapSupplier } from '@/legacy/Application/View/params/types';
import { RefMap } from '@/legacy/Application/View/refs/types';
import { StateData, StateSupplierWithData } from '@/legacy/Application/View/states/types';
import {
  ComponentCollection,
  ContainerMeta,
  EventHandler,
  Resolution,
  ResolutionTask,
  ResourceCategory,
  ValueSupplier,
} from '@/legacy/Application/View/types';
import { Variable } from '@/legacy/Application/View/vars/types';
import { ReleaseEnv } from '@/types/releaseEnv';

import { WorkflowIdentifier } from '../Workflows/types';
import { Action } from './Actions/types';
import { ViewWithEnvQuery } from './__generated__/queries.generated';

type ViewIdentifier = WorkflowIdentifier & {
  viewId: string;
  // TODO: move this to workflow identifier
  releaseEnv: ReleaseEnv;
};

interface ViewEntity {
  _fetched?: Date;
  id: string;
  name: string;
  created: Date;
  modified: Date;
  config?: StateWithHistory<ComponentCollection>; // new config
  componentConfig?: any; // old config -- need to deprecate this at a later point
  lastEvaluatedResources?: EvaluatedResourcesCollection;
  resolutionQueue?: ResolutionTask[]; // we use this to track any async resolution tasks
  currentVersionId: string;
  workflowId: string;
}

type ViewsMap = Record<ReleaseEnv, Record<string, ViewEntity>>;

interface UpdatedViewResponse {
  viewId: string;
  currentVersionId: string;
}

enum BuilderVersion {
  Initial = 1,
  CodeFiles = 2,
}

type CreateViewParams = {
  workflowId: string;
  name: string;
};

type RenameViewParams = ViewIdentifier & {
  name: string;
};

type UpdateVariablePayload = {
  varId: string;
  variable: Variable;
} & ViewIdentifier;

type UpdateContainerPayload = {
  delta: {
    value?: any;
    handlers?: Array<EventHandler>;
    meta?: Partial<ContainerMeta>;
  };
} & ViewIdentifier;

type UpdateActionPayload = ViewIdentifier & { actionId: string; config: Action };

type UpdateParamsPayload = ViewIdentifier & { paramMapSupplier: ParamMapSupplier; paramId: string };

type DuplicationTarget = {
  sourceId: string;
  targetId: string;
  targetLocation: Location;
};

type DuplicateComponentsPayload = ViewIdentifier & {
  /**
   * Source if copying components across views or copying from an older state of the same view. If not specified, uses the current ComponentCollection
   */
  sourceConfig: ComponentCollection | undefined;
  targets: DuplicationTarget[];
};

type RemoveComponentsPayload = ViewIdentifier & { componentIds: string[] };

type UpdateComponentPayload = {
  componentId: string;
  delta: {
    value?: any;
    chrome?: Record<string, any>;
    handlers?: Array<EventHandler>;
    action?: string;
  };
} & ViewIdentifier;

type UpdateRefsPayload = {
  updates: RefMap;
  deletes: string[];
} & ViewIdentifier;

type ValueSupplierPayload = {
  supplierId: string;
  value: ValueSupplier;
} & ViewIdentifier;

type ComponentDataPayload = {
  componentId: string;
  type: 'props' | 'fields';
  prop: string;
  data: Resolution;
} & ViewIdentifier;

type DataPayload = {
  dataType: string;
  key: string;
  data: Resolution;
} & ViewIdentifier;

type RenameComponentPayload = {
  oldId: string;
  newId: string;
} & ViewIdentifier;

type RunActionParams = {
  componentId?: string;
  actions: string[];
  asOperator: boolean;
} & ViewIdentifier;

type SyncSharedStatePayload = ViewIdentifier & { sharedStates: StateSupplierWithData[] };

type MutateStatePayload = ViewIdentifier & StateSupplierWithData;

type SetStatePayload = ViewIdentifier & { name: string } & StateData;

type SetDatasourceParamRefPayload = ViewIdentifier & { instanceId: string; paramRef: string };

type ResourceMatch = {
  category?: ResourceCategory;
  expanded: string;
  display: string;
  preview?: any;
  meta?: Record<string, any>;
};

type OperatorViewWithEnv = Omit<ViewWithEnvQuery['view_with_env'], 'view'> & {
  view: ViewEntity;
  workflowId: string;
};

export {
  OperatorViewWithEnv,
  BuilderVersion,
  ViewIdentifier,
  ViewEntity,
  ViewsMap,
  UpdatedViewResponse,
  CreateViewParams,
  RenameViewParams,
  UpdateVariablePayload,
  UpdateActionPayload,
  UpdateParamsPayload,
  UpdateContainerPayload,
  DuplicateComponentsPayload,
  RemoveComponentsPayload,
  UpdateComponentPayload,
  UpdateRefsPayload,
  ValueSupplierPayload,
  ComponentDataPayload,
  DataPayload,
  RenameComponentPayload,
  RunActionParams,
  SyncSharedStatePayload,
  MutateStatePayload,
  SetStatePayload,
  SetDatasourceParamRefPayload,
  ResourceMatch,
  DuplicationTarget,
};
