import isPlainObject from 'lodash/isPlainObject';

import { getOrgFromCache } from '@/hooks/useOrg';
import { DatasourceType } from '@/legacy/Application/View/datasources/types';
import { BasetenPageEnum, calcSwitchPath } from '@/routes';
import { safeStringify } from '@/utils/json';
import copy from 'copy-to-clipboard';
import prependHttp from 'prepend-http';
import { parseUrl } from 'query-string';

import { refreshDataSources, setState } from '..';
import { enqueueNotification } from '../../../ui/Notifier';
import { setComponentField } from '../ViewConfigSlice';
import { invokeDatasource } from '../data-resolution';
import { wrapResolutionValue } from '../utils';
import {
  Action,
  ActionAPI,
  ActionType,
  ControlComponentAction,
  ControlComponentOutput,
  CopyToClipboard,
  CopyToClipboardOutput,
  GoToUrlAction,
  GoToViewAction,
  GoToViewOutput,
  RefreshDataSources,
  RefreshDataSourcesOutput,
  RunActionOutput,
  RunWorkletAction,
  SetStateAction,
  SetStateOutput,
  ShowNotificationAction,
  ShowNotificationOutput,
  TriggerQueryAction,
  TriggerQueryOutput,
} from './types';

async function saveState(
  { dispatch, viewIdentifier, resolveValue }: ActionAPI,
  { saveState: name, value }: SetStateAction,
): Promise<SetStateOutput> {
  if (!name) {
    return;
  }
  const resolution = await resolveValue(value, { tryEquation: true });
  const { payload } = await dispatch(
    setState({
      ...viewIdentifier,
      name,
      value: resolution.resolvedValue,
    }),
  );

  return payload;
}

async function triggerQuery(
  { dispatch, viewIdentifier, resolveParamRef, asOperator }: ActionAPI,
  { queryId, paramRef, saveState: stateName }: TriggerQueryAction,
): Promise<TriggerQueryOutput> {
  if (!queryId || !stateName) {
    return;
  }
  const org = getOrgFromCache();
  let params: Record<string, any> = {};
  if (paramRef) {
    params = await resolveParamRef(paramRef);
  }
  try {
    const results = await invokeDatasource(
      {
        type: DatasourceType.Query,
        queryId,
      },
      params,
      viewIdentifier,
      asOperator,
      org?.dualEnvEnabled,
    );
    const { payload } = await dispatch(
      setState({
        ...viewIdentifier,
        name: stateName,
        value: results,
      }),
    );
    return { ...payload, params };
  } catch (ex) {
    const { payload } = await dispatch(
      setState({
        ...viewIdentifier,
        name: stateName,
        error: ex.message,
      }),
    );
    return { ...payload, params };
  }
}

async function controlComponent(
  { dispatch, viewIdentifier, resolveValue }: ActionAPI,
  { componentId, field, value }: ControlComponentAction,
): Promise<ControlComponentOutput> {
  if (!componentId || !field) {
    return;
  }
  const resolution = await resolveValue(value);
  const { payload } = await dispatch(
    setComponentField({
      ...viewIdentifier,
      componentId,
      id: field,
      data: wrapResolutionValue(resolution.resolvedValue, resolution.status),
    }),
  );
  return payload;
}

async function runWorklet(
  { dispatch, viewIdentifier, resolveParamRef, asOperator }: ActionAPI,
  { workletId, paramRef, saveState: stateName }: RunWorkletAction,
) {
  if (!workletId || !stateName) {
    return;
  }
  let params: Record<string, any> = {};
  if (paramRef) {
    params = await resolveParamRef(paramRef);
  }
  try {
    const results = await invokeDatasource(
      {
        type: DatasourceType.Worklet,
        workletId,
      },
      params,
      viewIdentifier,
      asOperator,
    );
    const { payload } = await dispatch(
      setState({
        ...viewIdentifier,
        name: stateName,
        value: results,
      }),
    );
    return payload;
  } catch (ex) {
    const { payload } = await dispatch(
      setState({
        ...viewIdentifier,
        name: stateName,
        error: ex.message,
      }),
    );
    return payload;
  }
}

async function goToView(
  { viewIdentifier, resolveParamRef, asOperator, extra }: ActionAPI,
  { viewId, paramRef }: GoToViewAction,
): Promise<GoToViewOutput> {
  let qsParams: Record<string, any> = {};
  if (paramRef) {
    qsParams = await resolveParamRef(paramRef);
  }
  const org = getOrgFromCache();

  const path = calcSwitchPath(
    asOperator ? BasetenPageEnum.ViewOperator : BasetenPageEnum.View,
    org?.dualEnvEnabled,
    {
      ...viewIdentifier,
      viewId,
    },
    qsParams,
  );
  extra.history.push(path);

  return { params: qsParams };
}

async function goToUrl({ resolveValue }: ActionAPI, { url }: GoToUrlAction): Promise<void> {
  if (!url) {
    return;
  }
  const resolvedUrl = await resolveValue(url);
  const parsedUrl = parseUrl(prependHttp(resolvedUrl.resolvedValue));
  window.location.href = parsedUrl.url;
}

async function showNotification(
  { dispatch, resolveValue }: ActionAPI,
  { message, variant }: ShowNotificationAction,
): Promise<ShowNotificationOutput> {
  if (!message) {
    return;
  }
  const resolvedMessage = await resolveValue(message);

  const { payload } = await dispatch(
    enqueueNotification({
      message: safeStringify(resolvedMessage.resolvedValue),
      variant: variant || 'default',
    }),
  );
  return payload;
}

async function copyToClipboard(
  { resolveValue }: ActionAPI,
  { value: text }: CopyToClipboard,
): Promise<CopyToClipboardOutput> {
  if (!text) {
    return;
  }
  const { resolvedValue } = await resolveValue(text);

  if (isPlainObject(resolvedValue) || Array.isArray(resolvedValue)) {
    // Stringify JSON-like data
    const stringified = JSON.stringify(resolvedValue, null, 2);
    copy(stringified);
    return { value: stringified };
  }

  copy(resolvedValue);
  return { value: resolvedValue };
}

async function dispatchRefreshDataSources(
  { dispatch, viewIdentifier }: ActionAPI,
  _action: RefreshDataSources,
): Promise<RefreshDataSourcesOutput> {
  const { payload } = await dispatch(refreshDataSources(viewIdentifier));
  return payload;
}

async function runAction(api: ActionAPI, action: Action): Promise<RunActionOutput> {
  switch (action.type) {
    case undefined:
      // empty action
      return;
    case ActionType.SetState:
      return saveState(api, action);
    case ActionType.TriggerQuery:
      return triggerQuery(api, action);
    case ActionType.ControlComponent:
      return controlComponent(api, action);
    case ActionType.RunWorklet:
      return runWorklet(api, action);
    case ActionType.GoToView:
      return goToView(api, action);
    case ActionType.GoToUrl:
      return goToUrl(api, action);
    case ActionType.ShowNotification:
      return showNotification(api, action);
    case ActionType.CopyToClipboard:
      return copyToClipboard(api, action);
    case ActionType.RefreshDataSources:
      return dispatchRefreshDataSources(api, action);
  }
}

export { runAction };
