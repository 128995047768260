import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';

import { getComponentDefinition } from '@/legacy/Application/View/components/definitions';
import { ComponentConfig } from '@/legacy/Application/View/components/types';
import { DatasourceBinding, DatasourceType } from '@/legacy/Application/View/datasources/types';
import { callWorklet, runQuery } from '@/legacy/Application/View/datasources/utils';
import { ParamMapSupplier } from '@/legacy/Application/View/params/types';
import { RefType } from '@/legacy/Application/View/refs/types';
import { getPathToSupplierId } from '@/legacy/Application/View/refs/utils';
import {
  ComponentCollection,
  RefData,
  RenderAs,
  Resolution,
  ResolutionStatus,
  ResolutionTarget,
  ResolutionTask,
  TargetsPartition,
  ValueSupplier,
} from '@/legacy/Application/View/types';
import {
  buildParamsWithRefs,
  getRenderFormatForPropType,
  getResolvedValue,
  hasRefs,
  renderAs,
  renderWithRefs,
} from '@/legacy/Application/View/utils';
import { Variable, VariableType } from '@/legacy/Application/View/vars/types';
import { runJSCode, runPython } from '@/legacy/Application/View/vars/utils';
import humanize from 'humanize-string';
import hash from 'object-hash';

import { WorkflowIdentifier } from '../Workflows/types';
import { ViewEntity } from './types';

const EMPTY_PARAM_HASH = hash({});

function determineResolutionStatus(listOfStatus: ResolutionStatus[]): ResolutionStatus {
  let pendingCount = 0;
  for (let i = 0; i < listOfStatus.length; i += 1) {
    const status = listOfStatus[i];
    if (status === ResolutionStatus.Unresolved || status === ResolutionStatus.Errored) {
      return status;
    }
    if (status === ResolutionStatus.Pending) {
      pendingCount += 1;
    }
  }
  if (pendingCount > 0) {
    return ResolutionStatus.Pending;
  }
  return ResolutionStatus.Resolved;
}

function categorizeResolutionTarget(
  supplierId: string,
  refs: string[],
  name: string,
  status: ResolutionStatus,
  unresolved: ResolutionTarget[],
  resolved: ResolutionTarget[],
) {
  const target: ResolutionTarget = {
    supplierId,
    refs: refs || [],
    name,
  };
  if (status === ResolutionStatus.Resolved) {
    resolved.push(target);
  } else {
    unresolved.push(target);
  }
}

function expandComponentData(
  data: Record<string, Resolution>,
  components: Record<string, ComponentConfig>,
  values: Record<string, ValueSupplier>,
): TargetsPartition {
  const unresolved: ResolutionTarget[] = [];
  const resolved: ResolutionTarget[] = [];
  Object.keys(components).forEach((childId) => {
    const component = components[childId];
    // setup data resolvers based on default props/fields according to component definitions
    const def = getComponentDefinition(component.type);
    // we need to check `def` here to ensure it's a definition we are aware of
    // it's possible that we have a user defined "type"; but that's not the case today
    // so we will add in those checks when we come across this in the future
    if (def) {
      // this portion is so we can allow for specifically `headerText` property of the component
      // "chrome" to be able to pick up dynamic references and resolve with dynamic values
      // it could be a bit cleaner? but perhaps at the cost of other areas; so anyways
      // we can revisit this later
      const { meta } = def;
      if (meta.chrome.header) {
        const headerTextSupplierId = component.chrome.headerText;
        const headerText = values[headerTextSupplierId];
        const headerHasRefs = headerText ? hasRefs(headerText) : false;
        const supplierData = {
          status: headerHasRefs ? ResolutionStatus.Unresolved : ResolutionStatus.Resolved,
          resolvedValue: headerText?.value || humanize(`Untitled ${component.type}`),
          as: RenderAs.String,
        } as Resolution;
        data[headerTextSupplierId] = supplierData;
        categorizeResolutionTarget(
          headerTextSupplierId,
          headerText?.refs,
          `${childId}.headerText`,
          supplierData.status,
          unresolved,
          resolved,
        );
      }
      // ... now we can work on props
      def.props.forEach((prop) => {
        const propSupplierId =
          component.propSuppliers[prop.id] || component.propSuppliers[prop.alias];
        const propSupplier = values[propSupplierId];
        const supplierHasRefs = propSupplier ? hasRefs(propSupplier) : false;
        const supplierData = {
          status: supplierHasRefs ? ResolutionStatus.Unresolved : ResolutionStatus.Resolved,
          resolvedValue: propSupplier?.value ?? prop.defaultValue,
          as: getRenderFormatForPropType(prop.type),
        } as Resolution;
        data[propSupplierId] = supplierData;
        categorizeResolutionTarget(
          propSupplierId,
          propSupplier?.refs,
          `${childId}.${prop.id}`,
          supplierData.status,
          unresolved,
          resolved,
        );
      });
      // ... now fields
      if (def.fields) {
        def.fields.forEach((field) => {
          const fieldSupplierId = component.fields?.[field.name];
          const fieldData = data[fieldSupplierId];
          const resolvedValue = fieldData?.resolvedValue ?? field.defaultValue;
          // supplier data for fields behave slightly differently in that:
          // * it won't ever have refs
          // * it will always be evaluated to be in the form of a string (for now at least)
          // this means that we already know what the resolvedValue will be without a need to re-evaluate
          const supplierData = {
            status: ResolutionStatus.Resolved,
            resolvedValue,
            as: RenderAs.Raw,
          } as Resolution;
          if (fieldData?.resolvedValue !== resolvedValue) {
            data[fieldSupplierId] = supplierData;
          }
          categorizeResolutionTarget(
            fieldSupplierId,
            null,
            `${childId}.${field.name}`,
            supplierData.status,
            unresolved,
            resolved,
          );
        });
      }
    }
  });
  return [unresolved, resolved];
}

function expandDatasourceData(
  data: Record<string, Resolution>,
  datasources: Record<string, DatasourceBinding>,
  params: Record<string, ParamMapSupplier>,
): TargetsPartition {
  const resolved: ResolutionTarget[] = [];
  Object.keys(datasources).forEach((instanceId) => {
    const datasource = datasources[instanceId];
    const existingData = data[instanceId];
    let isResolved = false;
    if (!datasource.paramRef) {
      isResolved = !shouldInvoke(existingData, EMPTY_PARAM_HASH);
    } else {
      const datasourceParams = params[datasource.paramRef];
      if (isEmpty(datasourceParams?.map)) {
        // equivalent to an empty param
        isResolved = !shouldInvoke(existingData, EMPTY_PARAM_HASH);
      }
      // TODO(mike): it's possible there is further optimizations here
      // if we can confirm that a parameter has not changed and that
      // it also has no downstream dependencies we can compare the chksum
      // values and mark the datasource as resolved without having to queue
      // it for resolution; but for now, let's roll with assuming we always
      // have to queue when we have params (and can do the chksum compare
      // downstream)
    }
    data[instanceId] = {
      status: ResolutionStatus.Pending,
      as: RenderAs.Raw,
      ...existingData,
    };
    if (isResolved) {
      resolved.push({
        supplierId: instanceId,
        refs: [] as string[],
        name: datasource.type,
      });
    }
  });
  return [[], resolved];
}

function expandVariableData(
  data: Record<string, Resolution>,
  vars: Record<string, Variable>,
  params: Record<string, ParamMapSupplier>,
): TargetsPartition {
  const resolved: ResolutionTarget[] = [];
  Object.keys(vars).forEach((varId) => {
    const variable = vars[varId];
    const existingData = data[varId];
    let isResolved = false;
    if (!variable.paramRef) {
      isResolved = !shouldInvoke(existingData, EMPTY_PARAM_HASH);
    } else {
      const variableParams = params[variable.paramRef];
      if (isEmpty(variableParams?.map)) {
        // equivalent to an empty param
        isResolved = !shouldInvoke(existingData, EMPTY_PARAM_HASH);
      }
    }
    data[varId] = {
      status: ResolutionStatus.Pending,
      as: RenderAs.Raw,
      ...existingData,
    };
    if (isResolved) {
      resolved.push({
        supplierId: varId,
        refs: [] as string[],
        name: variable.name,
      });
    }
  });
  return [[], resolved];
}

function followRef(collection: ComponentCollection, refId: string): RefData | null {
  const refTarget = collection.refs[refId];
  if (!refTarget) {
    // bad ref
    return null;
  }
  const path = getPathToSupplierId(refTarget);
  if (!path.length) {
    // dead path
    return null;
  }
  const supplierId = get(collection, path);
  return {
    target: refTarget,
    supplierId,
    data: collection.resolvedData[supplierId],
  };
}

function traverseRefs(
  collection: ComponentCollection,
  refs: string[],
  callback: (refData: RefData | null) => void,
) {
  refs.forEach((ref) => {
    callback(followRef(collection, ref));
  });
}

function getResolvedRefsObject(collection: ComponentCollection, refs: string[]) {
  if (refs && refs.length > 0) {
    const resolvedRefs: Record<string, any> = {};
    refs.forEach((ref) => {
      const refData = followRef(collection, ref);
      if (refData) {
        const resolvedValue =
          getResolvedValue(refData) ?? collection.valueSuppliers[refData.supplierId]?.value;
        resolvedRefs[ref] = resolvedValue;
      }
    });
    return resolvedRefs;
  }
  return null;
}

function resolveDataForView(view: ViewEntity) {
  const collection = view.config?.present;

  if (!collection) {
    // this is a legacy view, don't do anything
    return;
  }

  const datasourceQueue = new Set<string>();
  view.resolutionQueue.forEach(({ id, type }) => {
    if (type === 'datasources') {
      datasourceQueue.add(id);
    }
  });

  const partitions: TargetsPartition[] = [
    expandComponentData(collection.resolvedData, collection.children, collection.valueSuppliers),
    expandDatasourceData(collection.resolvedData, collection.datasources, collection.params),
    expandVariableData(collection.resolvedData, collection.vars, collection.params),
  ];
  const unresolved = ([] as ResolutionTarget[]).concat(...partitions.map(head));
  const resolved = ([] as ResolutionTarget[]).concat(...partitions.map(last));

  function updateResolution(supplierId: string, status: ResolutionStatus, resolvedValue?: any) {
    const existing = collection.resolvedData[supplierId];
    collection.resolvedData[supplierId] = {
      ...existing,
      status,
      resolvedValue,
    };
  }

  function evaluateTarget({ supplierId, refs, name }: ResolutionTarget) {
    if (!supplierId) {
      return;
    }
    let newValue;
    const resolvedRefs = getResolvedRefsObject(collection, refs);
    const valueSupplier = collection.valueSuppliers[supplierId];
    const data = collection.resolvedData[supplierId];
    if (resolvedRefs) {
      // has refs of some sort
      newValue = renderWithRefs(valueSupplier, resolvedRefs);
    } else {
      // no refs
      newValue = data?.resolvedValue === undefined ? valueSupplier?.value : data.resolvedValue;
    }
    try {
      newValue = renderAs(newValue, data?.as);
    } catch (ex) {
      updateResolution(supplierId, ResolutionStatus.Errored, ex.message);
      return;
    }
    if (newValue === data.resolvedValue) {
      return;
    }
    updateResolution(supplierId, ResolutionStatus.Resolved, newValue);
  }

  function propagateError({ supplierId, refs, name }: ResolutionTarget) {
    if (!supplierId) {
      return;
    }
    if (refs && refs.length > 0) {
      let firstError;
      for (const ref of refs) {
        const refData = followRef(collection, ref);
        if (refData?.data?.status === ResolutionStatus.Errored) {
          firstError = refData.data.resolvedValue;
          break;
        }
      }
      const existingData = collection.resolvedData[supplierId];
      if (isUndefined(firstError)) {
        // missing resource
        updateResolution(
          supplierId,
          ResolutionStatus.Errored,
          `Resource dependency error found while resolving ${name}.`,
        );
      } else if (firstError !== existingData?.resolvedValue) {
        updateResolution(supplierId, ResolutionStatus.Errored, firstError);
      }
    }
  }

  // first we need to evaluate all already resolved targets
  resolved.forEach(evaluateTarget);

  // then we can deal with the unresolved by attepmting to resolve
  // them by following refs and evaluating when all refs are resolved
  // this loop will also help us identify any circular refs found
  // because at the end of this, nothing should be left unresolved
  let passesLeft = unresolved.length;
  let unresolvedTarget: ResolutionTarget;
  while ((unresolvedTarget = unresolved.pop()) && passesLeft > 0) {
    const { supplierId, refs } = unresolvedTarget;
    if (refs.length === 0) {
      // all unresolved targets are assumed to have refs, if this is not the case
      // then we need to flag them with an error; but this should not happen
      updateResolution(
        supplierId,
        ResolutionStatus.Errored,
        'An error occured while trying to resolve this value',
      );
      continue; // eslint-disable-line no-continue
    }
    const statuses: ResolutionStatus[] = [];
    traverseRefs(collection, refs, (refData) => {
      if (!refData?.data) {
        // ref not found
        statuses.push(ResolutionStatus.Errored);
        return;
      }
      if (
        refData.target.type === RefType.DatasourceQueries ||
        refData.target.type === RefType.DatasourceWorklet
      ) {
        datasourceQueue.add(refData.target.instanceId);
      }
      statuses.push(refData.data.status);
    });
    const statusOfRefs = determineResolutionStatus(statuses);
    if (statusOfRefs === ResolutionStatus.Resolved) {
      evaluateTarget(unresolvedTarget);
    } else if (statusOfRefs === ResolutionStatus.Pending) {
      updateResolution(supplierId, ResolutionStatus.Pending);
    } else if (statusOfRefs === ResolutionStatus.Errored) {
      propagateError(unresolvedTarget);
    } else {
      unresolved.unshift(unresolvedTarget);
      passesLeft -= 1;
    }
  }

  if (unresolved.length > 0) {
    // there are still unresolved, which means we have a circular ref somewhere
    unresolved.forEach((target) => {
      updateResolution(target.supplierId, ResolutionStatus.Errored, 'Circular reference detected');
    });
  }

  // finally, let's update the resolution queue
  view.resolutionQueue = [
    ...Array.from(datasourceQueue).map(
      (instanceId): ResolutionTask => ({ type: 'datasources', id: instanceId }),
    ),
    // Always try to resolve all variables so that they don't hold stale data when they have no
    // dependencies since the value is always exposed in the Explorer panel. We will still skip
    // re-evaluating their code if the parameters are unchanged
    ...Object.keys(collection.vars).map((id): ResolutionTask => ({ type: 'vars', id })),
  ];
}

function buildDependentTasks(collection: ComponentCollection, refs: string[]) {
  const unresolved: ResolutionTask[] = [];
  if (refs && refs.length > 0) {
    refs.forEach((ref) => {
      const refData = followRef(collection, ref);
      if (refData?.data) {
        if (
          refData.target.type === RefType.DatasourceWorklet ||
          refData.target.type === RefType.DatasourceQueries
        ) {
          unresolved.push({
            type: 'datasources',
            id: refData.target.instanceId,
          });
        } else if (refData.target.type === RefType.Variables) {
          unresolved.push({
            type: 'vars',
            id: refData.target.varId,
          });
        }
      }
    });
  }
  return unresolved;
}

function resolveParams(
  collection: ComponentCollection,
  paramId: string,
  evaluatedTasks: ResolutionTask[],
) {
  let params: Record<string, any> = {};
  let status = ResolutionStatus.Resolved;
  let error = '';
  let chksum;
  const requires: ResolutionTask[] = [];

  if (paramId) {
    const param = collection.params[paramId];
    if (param) {
      if (param.refs && param.refs.length > 0) {
        status = ResolutionStatus.Unresolved;
        const resolvedRefs: Record<string, any> = {};
        const unresolvedTasks = buildDependentTasks(collection, param.refs).filter(
          (task) =>
            !evaluatedTasks.find(
              (evaluatedTask) => evaluatedTask.id === task.id && evaluatedTask.type === task.type,
            ),
        );
        if (unresolvedTasks.length > 0) {
          requires.push(...unresolvedTasks);
        } else {
          let hasError = false;
          param.refs.forEach((ref) => {
            const refData = followRef(collection, ref);
            if (refData?.data) {
              if (refData.data.status === ResolutionStatus.Resolved) {
                resolvedRefs[ref] = getResolvedValue(refData);
                return;
              }
              // in any other case, it's considered an error
              hasError = true;
            }
          });
          if (hasError) {
            status = ResolutionStatus.Errored;
            error = 'An error occured while trying to resolve these parameters.';
            chksum = hash(param.map);
          } else {
            status = ResolutionStatus.Resolved;
            params = buildParamsWithRefs(param.map, resolvedRefs);
          }
        }
      } else {
        // no refs
        params = buildParamsWithRefs(param.map, null);
      }
    }
  }

  return {
    status,
    error,
    params,
    chksum: chksum ?? (isEmpty(params) ? EMPTY_PARAM_HASH : hash(params)),
    requires,
  };
}

function shouldInvoke(data: Resolution | undefined, chksum: string) {
  if (!data) {
    return true;
  }
  return data.chksum !== chksum;
}

async function invokeDatasource(
  datasource: DatasourceBinding,
  params: Record<string, any>,
  workflowIdentifier: WorkflowIdentifier,
  asOperator: boolean,
  isDataEnabled: boolean = true,
) {
  switch (datasource.type) {
    case DatasourceType.Worklet:
      return callWorklet(workflowIdentifier, asOperator)(datasource.workletId, params);
    case DatasourceType.Query:
      return runQuery(workflowIdentifier, asOperator)(datasource.queryId, params, isDataEnabled);
  }
}

async function invokeCode(variable: Variable, params: Record<string, any>) {
  if (variable.type === VariableType.Python) {
    return runPython(variable.code, params);
  }
  return runJSCode(variable.code, params);
}

export {
  getResolvedRefsObject,
  resolveDataForView,
  buildDependentTasks,
  resolveParams,
  shouldInvoke,
  invokeDatasource,
  invokeCode,
};
