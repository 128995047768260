import LocalizedStrings from 'react-localization';

import { Levels, Levels as OrganizationTier } from '@/graphql';

const pricingTiers = new LocalizedStrings<Record<OrganizationTier, string>>({
  en: {
    [OrganizationTier.Basic]: 'Personal',
    [OrganizationTier.Starter]: 'Team',
    [OrganizationTier.Business]: 'Pro',
    [OrganizationTier.Blueprint]: 'Blueprint',
    [OrganizationTier.Startup]: 'Startup',
  },
});

const links = new LocalizedStrings({
  en: {
    // General links
    basetenHome: 'https://www.baseten.co/',
    basetenModelLibrary: 'https://www.baseten.co/library',
    basetenChangeLog: 'https://www.baseten.co/changelog',
    basetenSupport: 'mailto:support@baseten.co',
    basetenDocs: 'https://docs.baseten.co',
    basetenCustomers: 'https://www.baseten.co/customers',
    basetenStatus: 'https://status.baseten.co/',
    basetenTerms: 'https://www.baseten.co/terms-and-conditions',
    basetenPrivacy: 'https://www.baseten.co/privacy-policy',
    basetenCareers: 'https://jobs.ashbyhq.com/baseten',
    basetenTwitter: 'https://www.twitter.com/basetenco',
    // Blog links
    basetenBlog: 'https://www.baseten.co/blog',
    basetenBlogGPUGuides: 'https://www.baseten.co/blog/category/gpu-guides/',
    // NEW MODEL MANAGEMENT DOCS LINKS
    basetenNewModelDocsInvokeModel: 'https://docs.baseten.co/invoke/quickstart',
    basetenNewModelDocsAsyncInvokeModel: 'https://docs.baseten.co/invoke/async',
    basetenNewModelDocsAutoScaling: 'https://docs.baseten.co/performance/autoscaling',
    basetenNewModelDocsWhatIsADevDeployment:
      'https://docs.baseten.co/deploy/lifecycle#what-is-a-development-deployment',
    basetenNewModelDocsSelectInstanceType: 'https://docs.baseten.co/performance/instances',
    basetenNewModelDocsLifecycle: 'https://docs.baseten.co/deploy/lifecycle',
    basetenNewModelDocsDevDeployment:
      'https://docs.baseten.co/deploy/lifecycle#what-is-a-development-deployment',
    basetenNewModelDocsProdDeployment:
      'https://docs.baseten.co/deploy/lifecycle#what-is-a-production-deployment',
    basetenDocsSecretManagement: 'https://docs.baseten.co/observability/secrets',
    basetenDocsWebhookSecretManagement: 'https://docs.baseten.co/invoke/async-secure',
    basetenDocsAPIKeys: 'https://docs.baseten.co/observability/api-keys',
    basetenDocsInstanceReference: 'https://docs.baseten.co/performance/instances',
    basetenDocsGPUInstanceReference:
      'https://docs.baseten.co/performance/instances#gpu-instance-reference',
    basetenDocsCPUInstanceReference:
      'https://docs.baseten.co/performance/instances#cpu-only-instance-reference',
    basetenDocsAsyncInferenceTimeInQueue:
      'https://docs.baseten.co/observability/metrics#time-in-async-queue',
    basetenDocsAsyncInferenceQueueSize:
      'https://docs.baseten.co/observability/metrics#async-queue-size',
    basetenDocsAPIReference: 'https://docs.baseten.co/api-reference/overview',
    basetenDocsModelResources: 'https://docs.baseten.co/deploy/resources',
    basetenDocsModelResourcesAutoscaling: 'https://docs.baseten.co/deploy/autoscaling',
    // Other docs links
    basetenDocsBillingCredits: 'https://docs.baseten.co/observability/usage#credits',
    basetenDocsAppTutorial: 'https://baseten.gitbook.io/app-builder-docs/applications/tutorial',
    // Chains docs
    basetenDocsChains: 'https://docs.baseten.co/chains/getting-started',
    basetenDocsCallChainsAPIEndpoint:
      'https://docs.baseten.co/chains/guide#call-a-chains-api-endpoint',
    // Environment docs
    basetenDocsEnvironments: 'https://docs.baseten.co/deploy/environments',
    // Applications docs
    basetenDocsViews: 'https://baseten.gitbook.io/app-builder-docs/applications/views',
    basetenDocsComponents:
      'https://baseten.gitbook.io/app-builder-docs/applications/views/components',
    basetenDocsViewBindings:
      'https://baseten.gitbook.io/app-builder-docs/applications/views/bindings',
    basetenDocsVariableParameters:
      'https://baseten.gitbook.io/app-builder-docs/applications/views/bindings#parameters',
    basetenDocsEventHandlers:
      'https://baseten.gitbook.io/app-builder-docs/applications/views/event-handlers',
    basetenDocsWorklets: 'https://baseten.gitbook.io/app-builder-docs/applications/worklets',
    basetenDocsBlocks: 'https://baseten.gitbook.io/app-builder-docs/applications/worklets/blocks',
    basetenDocsFiles: 'https://baseten.gitbook.io/app-builder-docs/applications/files',
    basetenDocsConsole: 'https://baseten.gitbook.io/app-builder-docs/applications/files#console',
    basetenDocsCustomPythonEnvironment:
      'https://baseten.gitbook.io/app-builder-docs/applications/files/custom-python-environment',
    basetenDocsSystemPackages:
      'https://baseten.gitbook.io/app-builder-docs/applications/files/custom-python-environment#system-packages',
    basetenDocsGithubIntegration:
      'https://baseten.gitbook.io/app-builder-docs/settings/integrations#github',
    basetenDocsGithubImport:
      'https://baseten.gitbook.io/app-builder-docs/applications/files/import',
    // Data docs
    basetenDocsPostgres: 'https://baseten.gitbook.io/app-builder-docs/data/baseten-postgres',
    basetenDocsDataConnections: 'https://baseten.gitbook.io/app-builder-docs/data/data-connections',
    basetenDocsQueryBuilder: 'https://baseten.gitbook.io/app-builder-docs/data/query-builder',
    // Organization docs
    basetenDocsManagingMembers: 'https://docs.baseten.co/observability/access',
    // hubspot forms
    requestResourcesHubspotForm: 'https://share.hsforms.com/1Y1T1of8FTnq246Wbvq60swd5zj5',
    contactUs: 'https://www.baseten.co/talk-to-us/',
    // Feedback forms
    sendFeedbackGoogleForm:
      'https://docs.google.com/forms/d/e/1FAIpQLSc1S_ZyreoBmEqMvqxGR7td-gNWGu0MXhfMUioHcBXn9EvWBQ/viewform?usp=sf_link',
    // Truss docs
    trussDocsHome: 'https://docs.baseten.co/truss-reference/overview',
    trussDocsUsage: 'https://truss.baseten.co/usage',
    trussPypi: 'https://pypi.org/project/truss/',
    trussDocsReferenceWatch: 'https://truss.baseten.co/reference/cli/watch',
    trussDocsReferenceInit: 'https://truss.baseten.co/reference/cli/init',
  },
});

function basetenDocsBlockReference(block: string) {
  return `${links.basetenDocsBlocks}#${block}`;
}

function basetenDocsComponentReference(component: string) {
  return `${links.basetenDocsComponents}#${component}`;
}

function basetenDocsBindingReference(binding: string) {
  return `${links.basetenDocsViewBindings}#${binding}`;
}

function getLocalizedPricingTierName(tier: Levels) {
  return pricingTiers[tier];
}

function getRequestResourcesLink(modelName: string) {
  return `${links.requestResourcesHubspotForm}?model_library_model=${modelName}`;
}

export {
  pricingTiers,
  getLocalizedPricingTierName,
  links,
  basetenDocsBlockReference,
  basetenDocsComponentReference,
  basetenDocsBindingReference,
  getRequestResourcesLink,
};
