import { FC } from 'react';

import DeployModelViaTruss from '@/pages/DeployModelViaTruss';
import Workspace from '@/pages/Workspace';
import { BasetenPageEnum } from '@/routes/types';
import asyncComponentLoader from '@/utils/react/loader';

const PageComponents: Partial<Record<BasetenPageEnum, FC>> = {
  [BasetenPageEnum.Workspace]: Workspace,
  [BasetenPageEnum.DeployModelViaTruss]: DeployModelViaTruss,
  [BasetenPageEnum.DeployModel]: asyncComponentLoader(() => import('@/pages/DeployModel')),
  [BasetenPageEnum.Model]: asyncComponentLoader(() => import('@/pages/Model')),
  [BasetenPageEnum.Chain]: asyncComponentLoader(() => import('@/pages/Chain')),
  [BasetenPageEnum.SharedEndpoint]: asyncComponentLoader(() => import('@/pages/SharedEndpoint')),
  [BasetenPageEnum.Application]: asyncComponentLoader(() => import('@/legacy/Application')),
  [BasetenPageEnum.Settings]: asyncComponentLoader(() => import('@/pages/Settings')),
  [BasetenPageEnum.Class]: asyncComponentLoader(() => import('@/legacy/Class')),
  [BasetenPageEnum.Onboarding]: asyncComponentLoader(() => import('@/pages/Onboarding')),
  [BasetenPageEnum.ViewOperator]: asyncComponentLoader(
    () => import('@/legacy/Application/View/Operator'),
  ),
  [BasetenPageEnum.ModelBuilder]: asyncComponentLoader(() => import('@/pages/ModelBuilder')),
  [BasetenPageEnum.Playground]: asyncComponentLoader(() => import('@/pages/Playground')),
  [BasetenPageEnum.CreateSharedEndpoint]: asyncComponentLoader(
    () => import('@/pages/CreateSharedEndpoint'),
  ),
};

export default PageComponents;
