import { StateSupplier } from '@/legacy/Application/View/states/types';

import { WorkflowEntity } from './types';

function insertSharedState(workflow: WorkflowEntity, stateSupplier: StateSupplier) {
  const { sharedStates: currentStates } = workflow;
  if (currentStates) {
    const existing = currentStates.find((sharedState) => sharedState.name === stateSupplier.name);
    if (!existing) {
      currentStates.push(stateSupplier);
    }
  }
}

export { insertSharedState };
