import { DataTabs } from '@/legacy/Data/types';

enum WorkspaceSections {
  Applications = 'applications',
  Models = 'models',
  Data = 'data',
}

interface MatchParams {
  tabId?: DataTabs;
  section?: WorkspaceSections;
}

export { WorkspaceSections, MatchParams };
