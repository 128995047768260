import { Typography } from '@mui/material';

import { styled } from '@/theme/styled';

import { ButtonProps, RadioProps } from './types';

const Group = styled('fieldset')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: theme.spacing(2),
}));

const Button = styled('label')<ButtonProps>(({ size, theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  borderColor: theme.palette.border.default,
  borderWidth: 1,
  color: theme.palette.text.default,
  borderRadius: 6,
  borderStyle: 'solid',
  padding: size === 'small' ? theme.spacing(1, 2) : theme.spacing(2, 3),
  cursor: 'pointer',
  userSelect: 'none',
  position: 'relative',
  transition: 'all 0.3s ease-in-out',

  '&:hover': {
    backgroundColor: theme.palette.background.subtle,
    borderColor: theme.palette.border.emphasis,
  },
}));

const Text = styled(Typography)({
  fontWeight: 500,
});

const Subtext = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.muted,
}));

const Radio = styled('input', {
  shouldForwardProp: (prop) => prop !== 'alert',
})<RadioProps>(({ theme, alert }) => ({
  position: 'absolute',
  top: -9999,
  left: -9999,

  '&:focus-visible + label, &:active + label': {
    outline: `1px solid ${theme.palette.border.infoEmphasis} !important`,
  },

  '&:checked + label': {
    backgroundColor: theme.palette.background.accent,
    borderColor: 'transparent',

    '&:before': {
      content: '""',
      border: `2px solid ${
        alert ? theme.palette.border.danger : theme.palette.border.infoEmphasis
      }`,
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      borderRadius: 6,
      position: 'absolute',
    },
  },
}));

const Emoji = styled(Typography)({});

Emoji.defaultProps = {
  variant: 'body1',
  paragraph: false,
};

Text.defaultProps = {
  variant: 'body1',
  paragraph: false,
};

Subtext.defaultProps = {
  variant: 'subtitle2',
};

export { Button, Emoji, Text, Subtext, Group, Radio };
